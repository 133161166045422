import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Dialog, DialogContent } from '@material-ui/core';
import PageLoader from '../../components/layout/ui/page-loader';

const publicUrl = process.env.PUBLIC_URL + '/';

const DESTINATION_WISH_LIST = loader('../../graphql/schema/destinationView/destinationwishlist.graphql');
const DELETE_DESTINATION_WISH_LIST = loader('../../graphql/schema/destinationView/delete-DestinationWishList.graphql');

const DestinationWishList = (props) => {

    const { history } = props;

    const [destinationwishlistData, setdestinationwishlistData] = useState([]);
    const [open, setOpen] = useState(false);
    const [dest_id, setDest_id] = useState('');

    const [deleteDestinationWishList] = useMutation(DELETE_DESTINATION_WISH_LIST, {
        onCompleted() {
            window.location.reload();
        }
    });

    const { data: list, loading } = useQuery(DESTINATION_WISH_LIST, {
        onCompleted() {
            if (list.getDestinationWishlist && list.getDestinationWishlist.length > 0) {
                setdestinationwishlistData(list.getDestinationWishlist);
            }
            else {
                setdestinationwishlistData([]);
            }
        }
    });

    const handleDeleteDestinationWishList = (value) => {
        setDest_id(value.id);
        setOpen(true);
    }

    const handleYes = () => {
        deleteDestinationWishList({
            variables: {
                id: dest_id
            },
        });
        setOpen(false);
    }

    const handleNo = () => {
        setOpen(false);
    }


    const handlecratenewDestinationWishList = () => {
        history.push('/create-destinationwish-list');
    }

    return (
        <>
            {loading && <PageLoader loading={loading} />}
            <div className="traveller-page">
                <div className="traveller-row">
                    <div className="traveller-head">
                        <div className="container">
                            <div className="traveller-head-row">
                                <div className="traveller-head-left">
                                    <h2>Destination Wishlist</h2>
                                </div>
                                <div className="traveller-head-right">
                                    <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="destination-body-new">
                            <div className="destination-body">
                                {destinationwishlistData.length <= 0 && <p className="no-data">No Data To Display</p>}
                                {destinationwishlistData.length > 0 && destinationwishlistData.map((value, index) => {
                                    return (
                                        <div className="destination-box" key={index}>
                                            <div className="box-left">
                                                <figure>
                                                    <img alt="" src={publicUrl + "assets/images/inner-page/opera.jpg"} />
                                                </figure>
                                            </div>
                                            <div className="box-center" >
                                                <h4>{value.destination || 'N/A'}</h4>
                                                <p>{value.city || 'N/A'}   {value.country || 'N/A'}</p>
                                            </div>
                                            <div className="box-right">
                                                <i className="la la-times" data-toggle="modal" data-target="#myModal" onClick={() => handleDeleteDestinationWishList(value)}></i>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="schedule-btn ">
                                <button className="btn btn-yellow m-3" onClick={handlecratenewDestinationWishList}>Create New Destination Wish List</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog aria-labelledby="customized-dialog-title" open={open}>
                    {/* <div className="box-right">
                        <i className="la la-times" data-toggle="modal" data-target="#myModal" onClick={handleNo}></i>
                    </div> */}
                    <DialogContent>
                        <div className="schedule-popup-div">
                            <h4>Are you sure you want to delete</h4>
                            <div className="delete-popup">
                                <button className="btn btn-yellow" onClick={handleYes}>yes</button>
                                <button className="btn btn-yellow" onClick={handleNo}>no</button>
                            </div>
                        </div>
                      
                    </DialogContent>
                    {/* <DialogActions >
                        <button className="btn btn-yellow" onClick={handleYes}>yes</button>
                        <button className="btn btn-yellow" onClick={handleNo}>no</button>
                    </DialogActions> */}
                </Dialog>
            </div>
        </>
    )
}

DestinationWishList.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);

export default enhance(DestinationWishList)
