import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import SignatureCanvas from 'react-signature-canvas'
import PageLoader from '../../components/layout/ui/page-loader';

const publicUrl = process.env.PUBLIC_URL + '/';

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql'); 
const TERMS = loader('../../graphql/schema/terms/terms.graphql');
const WALKER_TERMS_CONDITIONS = loader('../../graphql/schema/terms/walker-terms-condition.graphql');

const WalkerTermsTraveler = ( props ) => {

  const { history } = props;

  // const [signImage, setSignImage] = useState('');
  const [hasError, setHasError] = useState('');

  const { data: myDetails ,loading } = useQuery(MY_DETAILS);

  const { data: terms } = useQuery(TERMS);

  const [updateWalkerTermsAndConditions] = useMutation(WALKER_TERMS_CONDITIONS, {
    onCompleted() {
      history.push( '/' );
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  let sigPad = {
    width: 500,
    height: 200,
  };

  const handleClear = () => {
    sigPad.clear();
  };

  const handleSave = () => {
    sigPad.getTrimmedCanvas().toDataURL('image/png');
  };

  const handleSubmit = () => {
    updateWalkerTermsAndConditions({
      variables: {
        data: {
          status: true,
          digital_signature: sigPad.getTrimmedCanvas().toDataURL('image/png'),
        }
      },
    });
  }
  
  return (
    <>
      {loading && <PageLoader loading={loading} />}
      {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
      <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Welcome Profile</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="travelle-body">
              <div className="profile-row">
                <div className="profile-left">
                <figure>
                    <img src={ myDetails && myDetails.me.avatar ?  myDetails.me.avatar : 'assets/images/inner-page/profile.jpg' } alt="profile"  />
                  </figure>
                  <div className="profile-title">
                    <h4>Welcome Back,</h4>
                    <p>
                      {myDetails && myDetails.me.first_name}
                      {' '}
                      {myDetails && myDetails.me.last_name}
                    </p>
                  </div>
                </div>
                <div className="profile-right">
                  <figure>
                    <img src={publicUrl + "assets/images/favicon.png"} alt="" />
                  </figure>
                  <span>Traveler</span>
                </div>
              </div>
              <div className="welcome-sign welcome-sign-title">
                <span>Walker Terms</span>
                <p>Please read and sign below</p>
              </div>
              <div className="welcome-terms-para">
                <span>Terms Dated <br />
                 {terms && terms.term.updated_at}<br />
                </span>
                <div dangerouslySetInnerHTML={{ __html: terms && terms.term.description}} />
              </div>
              <div className="welcome-sign">
                <span>Sign with fingers after reading</span>
              </div>
              <div className="welcome-form">
                <p className="welcome-form-btn">
                  <span className="edit-btn" onClick={handleSave}>Save</span>
                  <span className="delete-btn" onClick={handleClear}>Clear</span></p>
                <div className="form-in finger-print-div">
                <SignatureCanvas penColor='black'
                  ref={(ref) => { sigPad = ref }}
                  canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                />
                  <button className="btn btn-yellow" onClick={handleSubmit}>Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

WalkerTermsTraveler.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(WalkerTermsTraveler );
