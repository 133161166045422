import firebase from "firebase/app";
import 'firebase/firestore'

var firebaseConfig = {
    apiKey: "AIzaSyCLYkh57ZLBd9M1zD6T9_u9Fi7D7JCfHO0",
    authDomain: "huvrapp-81467.firebaseapp.com",
    databaseURL: "https://huvrapp-81467.firebaseio.com",
    projectId: "huvrapp-81467",
    storageBucket: "huvrapp-81467.appspot.com",
    messagingSenderId: "1011884275815",
    appId: "1:1011884275815:web:570f6026d18f470e05fcc2",
    measurementId: "G-S73YPV3HY0"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


export default firebase;