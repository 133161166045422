import React,{ useState,useEffect} from 'react';
import { useMutation,useApolloClient} from '@apollo/react-hooks';
import { TextField } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';
import { loader } from 'graphql.macro';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import PageLoader from '../../layout/ui/page-loader';
import UserUtils from '../../../utilities/UserUtils';

const UPDATE_NEW_PASSWROD= loader('./../../../graphql/schema/auth/update-forgot-password.graphql');

const UpdateForgotPassword=(props)=>{
const {history}=props;
const apolloClient = useApolloClient();
const [hasError, setHasError] = useState('');
const [passCheck, setPassCheck] = useState( false );
const [phoneOtp, setPhoneOtp] = useState('');
const [successMessage,setSuccessMessage] = useState('');
const [phoneNumber, setPhoneNumber] = useState("");


useEffect(() => {
 const phone_number = UserUtils.getPhoneNumber();
 
 if(phone_number !== null){
  setPhoneNumber(phone_number);
 }else{
  history.push('/forgot-password');
 }
 return () => {
   }
}, [])

const handleChangeOtp = (otp) => {
 setPhoneOtp(otp);
}


const postUpdateForgotPassword = (resUser) => {
 console.log(resUser,'------- post user')
 apolloClient.writeData({ data: { ispostUpdateForgotPassword: true } });
 let error = {};
 if (resUser.status === "PASSWORD_UPDATED") {
  setSuccessMessage(resUser.message);
    history.push('/select-destinations');
  }
  else if (resUser.status === "PASSWORD_UPDATED_FAIL") {
    error['PASSWORD_UPDATED_FAIL'] = resUser.message;
    setHasError(error);
  }else if (resUser.status === "WRONG_OTP") {
   error['WRONG_OTP'] = resUser.message;
   setHasError(error);
 }
 }
 const [submitUpdateForgotPassword, { loading: UpdateForgotPasswordLoading }] = useMutation(UPDATE_NEW_PASSWROD, {
    onCompleted({
      updateForgottenPassword:{
        status,
        message
    },
    }) {
      if( status === "PASSWORD_UPDATED"  ) {
        postUpdateForgotPassword({ status, message });
      }
     
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });
 const validate = ( values ) => {
    const errors = {};

    if ( !values.phone_Otp) {
      errors.phone_Otp = 'OTP is required.';
    } 
    if ( !values.new_password ) {
      errors.new_password = 'New password is required.';
    }

    if ( !values.confirm_new_password ) {
      
      errors.confirm_new_password = 'Confirm new password is required.';
    } else if ( values.confirm_new_password !== values.new_password ) {
      errors.confirm_new_password = 'Password and confirm password should be same.';
    }
   
    return errors;
  };

 const onSubmitUpdateForgotPassword=(values)=>{
  console.log(values,'check values');
  submitUpdateForgotPassword({
      variables: {
        data: {
        otp: values.phone_Otp,
        phone:phoneNumber,
        password: values.new_password,
        password_confirmation :values.confirm_new_password, 
     },
   },
 });
};
return(
 <>
    <div className="traveller-page">
    {UpdateForgotPasswordLoading && <PageLoader loading={UpdateForgotPasswordLoading} />}
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2> Update Forgot Password</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>
         <div className="container">
            <div className="travelle-body">
              <div className="edit-form-row">
              {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
              {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
             <Form
              onSubmit={onSubmitUpdateForgotPassword}
              validate={ validate }
               render={ ( {handleSubmit,submitting } ) => (
                <form
                  onSubmit={ handleSubmit}
                  noValidate
                >
              <React.Fragment>
             <div className="form-in">
              <span>Enter OTP</span>
               <Field
                 id="phone_Otp"
                 placeholder="Enter OTP"
                 type="password"
                 name="phone_Otp"
                 margin="normal"
                 value={phoneOtp}
                 onChange={handleChangeOtp}
                 fullWidth
                 component = {TextField}
                 variant="outlined"
              />
              </div>
             <div className="form-in">
                <span>New Password</span>
                <Field
                  id="new_password"
                  placeholder="New Password"
                  type="password"
                  name="new_password"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  component={ TextField }
                  onFocus={() => { setPassCheck( true ); }}
                  onBlur={()=> { setPassCheck( false ); }}
                 />
                {passCheck === true && (
                 <ul className="form-in">
                   <li>
                     Include at least 8 characters
                   </li>
                   <li>
                     Include upper and lower case characters
                   </li>
                   <li>
                     Include at least 1 number
                   </li>
                   <li>
                     Passwords match
                   </li>
                 </ul>
                 )}
                 </div>
                <div className="form-in">
                <span>Confirm New Password</span>
                    <Field
                      id="confirm_new_password"
                      placeholder="Confirm New Password"
                      type="password"
                      name="confirm_new_password"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      onFocus={() => { setPassCheck( true ); } }
                      onBlur={() => { setPassCheck( false ); } }
                 />
                </div>
              </React.Fragment>
                <div className="form-btn">
                  <Button type="submit" disabled={submitting}>Reset password</Button>
               </div>
               </form>
               )}
            />
              </div>
            </div>
            </div>
          </div>
        </div>
       </>
  );
};
               
UpdateForgotPassword.propTypes = {
 history: PropTypes.object.isRequired,
};

const enhance = compose(
 withRouter,
);

export default enhance(UpdateForgotPassword);
