import React from 'react';
import PropTypes from 'prop-types';

const ConnectionStatus = (props) => {
  let status = props.connected ? 'Connected' : 'Disconnected';
  return (
    <div>
      Connection Status: <b>{status}</b>
    </div>
  );
}

ConnectionStatus.propTypes = {
  connected: PropTypes.bool.isRequired,
}

export default ConnectionStatus;