import React, { useState, useCallback } from 'react';
import { OTPublisher, OTSession, preloadScript } from 'opentok-react';
import { useMutation } from "@apollo/client";
import { loader } from 'graphql.macro';
import Checkbox from './check-box';
import ConnectionStatus from './connection-status';
import WalkerVideoCallRequest from './walker-videocall-request';

const SEND_BOOKING_REQUEST_TO_WALKER = loader('../../graphql/schema/video-call/send-booking-request-to-walker.graphql');
//const WALKER_ACCEPT_REJECT_BOOKING_REQUEST = loader('../../graphql/schema/video-call/walker-accept-reject-booking-request.graphql');
//const VERIFY_VIDEOCALL_TRAVELLER_VONAGE = loader('../../graphql/schema/video-call/verify-videocall-traveller-vonage.graphql');
//const EXTEND_VIDEOCALL = loader('../../graphql/schema/video-call/extend-videocall.graphql');
//const END_VIDEOCALL_VONAGE = loader('../../graphql/schema/video-call/end-videocall-vonage.graphql');


const apiKey = process.env.REACT_APP_API_KEY;
const sessionId = process.env.REACT_APP_SESSION_ID;
const token = process.env.REACT_APP_TOKEN;

const VideoCall = () => {

  const [error, setError] = useState(null);
  const [audio, setAudio] = useState(true);
  const [video, setVideo] = useState(true);
  const [connected, setConnected] = useState(false);

  const [sendBookingRequestToWalker] = useMutation(SEND_BOOKING_REQUEST_TO_WALKER);

  const toggleAudio = useCallback(() => setAudio(!audio));

  const toggleVideo = useCallback(() => setVideo(!video));

  const onError = (err) => {
    setError({ error: `Failed to publish: ${err.message}` });
  }

  const sessionEvents = {
    sessionConnected: () => {
      setConnected({ connected: true });
    },
    sessionDisconnected: () => {
      setConnected({ connected: false });
    }
  }

  return (
    <div>
      <WalkerVideoCallRequest walkerBookingRequest={sendBookingRequestToWalker} />
      <OTSession
        apiKey={apiKey}
        sessionId={sessionId}
        token={token}
        eventHandlers={sessionEvents}>
        <ConnectionStatus connected={connected} />

        {error ? <div id="error">{error}</div> : null}
        <OTPublisher
          properties={{
            publishAudio: audio,
            publishVideo: video,
          }}
          onError={onError} />
        <Checkbox
          label="Publish Audio"
          checked={audio}
          onChange={toggleAudio}
        />

        <Checkbox
          label="Publish Video"
          checked={video}
          onChange={toggleVideo}
        />
      </OTSession>
    </div>
  );
}

export default preloadScript(VideoCall);