import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { TextField } from 'final-form-material-ui';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { loader } from 'graphql.macro';
import ReactFlagsSelect from 'react-flags-select';

const WALKER_KIT = loader('../../graphql/schema/walker/walker-kit-order.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';

const WalkerKit = ( props ) => {

  const { history } = props;

  const [countryCode, setCountryCode] = useState( '' );
  const [hasError, setHasError] = useState( '' );
  const [openPopup, setOpenPopup] = useState( false );

  const [updateWalkerKit] = useMutation(WALKER_KIT, {
    onCompleted() {
      setOpenPopup( true );
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  const validate = (values) => {
    const errors = {};

    if (!values.street_address) {
      errors.street_address = 'Street address is required.';
    }

    if (!values.city) {
      errors.city = 'City is required.';
    }

    if (!values.state) {
      errors.state = 'State is required.';
    }

    if (!values.zip_code) {
      errors.zip_code = 'Zipcode is required.';
    }

    return errors;
  };

  const onSubmitWalkerKit = (values) => {
    if ( countryCode === '' || countryCode === undefined || countryCode === null ) {
      setHasError( 'Country is required.');
    } else {
      updateWalkerKit({
        variables: {
          data: {
            city: values.city,
            street_address: values.street_address,
            state: values.state,
            country: countryCode,
            zip_code: values.zip_code,
          },        
        },
      });
    }
    
  };

  const handleClosePopup = () => {
    setOpenPopup( false );
    history.push('/');
  }
  
  return (
    <>
      <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Walker Kit</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="walkerkit-body">
              <div className="walkerkit-row">
                <figure>
                  <img src={publicUrl + "assets/images/inner-page/phoneKit.png"} alt="" />
                  <span>$0.00 USD</span>
                </figure>
                <p>In Beta, we HIGHLY RECOMMEND using a stabilizer stick to enhance the user experience for Traverlers. You will get more business if you provide a quality experience. Instead of requiring you to purchase that here, we are allowing you to use your own or purchase one on a marketplace like Amozon.com.</p>
              </div>
              <div className="walkerkit-row">
                <figure>
                  <img src={publicUrl + "assets/images/inner-page/phoneKit.png"} alt="" />
                  <span>$0.00 USD</span>
                </figure>
                <p>Please complete this form with your current mailing address and we will send you some Huvr Swag in the near future.</p>
              </div>
              {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
         
              <Form
                  onSubmit={onSubmitWalkerKit}
                  validate={validate}
                  
                  render={({ handleSubmit, submitting }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                      className="edit-form-row"
                    >
                      <div className="form-in">
                        <span>COUNTRY</span>
                        <Field id="country" name="country">
                          {({ input }) => (
                            <div>
                              <ReactFlagsSelect
                                {...input}
                                searchPlaceholder="Search countries"
                                searchable
                                selected={countryCode}
                                onSelect={code => setCountryCode(code)}
                              />
                            </div>
                          )}
                        </Field>
                       
                      </div>
                   
                      <div className="form-in">
                        <span>Street Address</span>
                        <Field
                          id="street_address"
                          placeholder=""
                          type="text"
                          name="street_address"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>City</span>
                        <Field
                          id="City"
                          placeholder=""
                          type="text"
                          name="city"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>State</span>
                        <Field
                          id="state"
                          placeholder=""
                          type="text"
                          name="state"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                     

                      <div className="form-in">
                        <span>Zip Code</span>
                        <Field
                          id="zip_code"
                          placeholder=""
                          type="text"
                          name="zip_code"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in-check">
                        <Field
                          id="termsCondition"
                          component="input"
                          type="checkbox"
                          name="termsCondition"
                          margin="normal"
                          variant="outlined"                          
                        />
                     

                        <span>
                          I understand that Huvr is not requiring me to purchase a Walker Kit in Beta but a stabilizer stick is HIGHLY recommended
                          for the best user experience.
                        </span>
                      </div>
                      <div className="form-btn">
                        <Button type="submit" disabled={submitting}>Submit</Button>
                      </div>
                    </form>
                  )}
                />
         

            </div>
          </div>
        </div>
        <Dialog onClose={() => setOpenPopup( false )} aria-labelledby="simple-dialog-title" open={openPopup}>
          <div>
            <span>Walker Training kit order successfully</span>
            <Button onClick={ handleClosePopup }>OK</Button>
          </div>
        </Dialog>
      </div>
    </>
  )
}

WalkerKit.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance( WalkerKit );
