import React from 'react';
import { useEffect } from 'react';
import UserUtils from '../../utilities/UserUtils';
import { useState } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

const ScheduleWalker = () => {

  const [latLong, setLatLong] = useState('');
  const [popup, setPopUp] = useState(true);
  const [selectedMark, setSelectedmark] = useState(null);

  useEffect((data) => {

    const Data = UserUtils.getDestinationData(data);
    console.log(Data);
    setLatLong(JSON.parse(Data));
  }, []);


  const MapWithAMarker = withScriptjs(withGoogleMap(() =>
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: latLong.latitude, lng: latLong.longitude }}
    >
      <Marker
        position={{ lat: latLong.latitude, lng: latLong.longitude }}
        onClick={() => {
          setSelectedmark('park');
        }}
      />
      {selectedMark && (
        <InfoWindow
          position={{ lat: latLong.latitude, lng: latLong.longitude }}
          onClick={() => {
            setSelectedmark(null);
          }}
        >
          <div>
          <h3>hello</h3>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ));

  const handlepopUp = () => {
    setPopUp(false)
  }

  console.log(latLong);


  return (
    <>
      <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Select A Walker</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="travelle-body div-initail">
              <MapWithAMarker
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAd_8tk5z2ySbn4uRcZRXCSIjtac6kYGHI&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
              <div className="schedule-popup">
                <div>
                  {popup === true ?
                    <div className="schedule-popup-div">
                      <h4>Select A Walker</h4>
                      <p>To Begin Your Trip Select a Walker from Live Map</p>
                      <button className="btn btn-yellow" onClick={handlepopUp}>OK</button>
                    </div>
                    :
                    ''
                  }
                 {/* <div className="schedule-btn">
                    <button className="btn btn-yellow">Schedule Trip</button>
                </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ScheduleWalker;
