import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
   
const Help=()=>{

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };
return (
    <div className="traveller-page">
      <div className="traveller-row">
        <div className="traveller-head">
          <div className="container">
            <div className="traveller-head-row">
              <div className="traveller-head-left">
                <h2>Help&Support</h2>
              </div>
              <div className="traveller-head-right">
                <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="support-row">

              <div>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Open support ticket or give feedback</Typography>
                  </AccordionSummary>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography >How do I submit a support ticket or provide feedback?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Please go to <a href="">Traveler Support</a> to open a support ticket or provide feedback on the Huvr app.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Accordion>
              </div>

              <div>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Legal (As a Traveler)</Typography>
                  </AccordionSummary>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> Legal, privacy and other inquiries</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Terms of use 3/10/21 Privacy policy
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Accordion>
              </div>

              <div>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Payment options</Typography>
                  </AccordionSummary>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Can I add more than one credit card?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Yes. Click the hamburger menu in the top left of the screen.Then click on Payment Methods.From that screen,you can add multiple credit cards as payment methods.
                    </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Can I use Apple Pay or Google Pay?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          We have plans to turn on these payment methods in version 1.0, but for Beta, please use a credit card.
           </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >

                        <Typography>How can I add a Credit Card?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Click the hamburger menu in the top left of the screen.Then click on Payment Methods. There you will see a button for &quot; Add New Card &quot;
           </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"

                      >
                        <Typography>How can I change my default Credit Card?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Click the hamburger menu in the top left of the screen.Then click on Payment Methods.If you only have one card,that is your default card.If you have more than one card, the top card is your default card.If you want a different card to be default , slide the slider to the left and click on the edit button .Then click the button marked &quot;Set Card as Default&quot;
            </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Accordion>
              </div>

              <div>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Understanding Huvr (As a Traveler)</Typography>
                  </AccordionSummary>
                  <div>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Can I be a Traveler and a Walker?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Yes. Everyone who downloads the app is a Traveler. But people who would like to launch their own business as a walker can simply click the &quot;Make Money Walking&quot; section, complete the tasks and training, and start earning a great income as a walker.
          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Can I use Huvr to get discounts on travel, lodging and rental cars?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            In the near future, you will be able to get discounts on travel fares. In Beta, we have not turned that feature on.
           </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>How much do virtual trips cost on Huvr?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Each time you use Huvr, there is a $5.00 connection fee, plus a $1.00 per minute charge that the video is live.
          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Is Huvr available Worldwide?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Huvr will roll out specific cities globally and continue to expand for gig workers. By expressing interest we can tell you when your city goes live so you can secure your place. In terms for those using the app, it will be made available on IOS & Android and subject to each country and their release regulations.
          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography> What is Huvr?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Huvr is an app that connects people around the globe, and provides real time virtual travel experiences.
          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography> Who can apply to work with Huvr?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Any Traveler can apply to become a Huvr Walker. We are seeking independent contractors who have the flexibility to work in their local city - where there are events, landmarks and experiences the world wants to experience. Must be fit to walk, enjoy connecting with people and like the outdoors.
          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Why is there no social sharing?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            We do not allow social sharing of trips in Beta because we are in the final stages of testing the app. But in version 1.0, you will be able to share video clips of your trips on select social media sites.
          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Will there be more destinations coming online soon?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Yes. In Beta, you will see between 10 and 20 destinations go online. But in Version 1.0, we will be opening destinations to walkers all over the world.
          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>How often are Traveler charges rounded?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Traveler charges are rounded up every minute.
          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Accordion>
              </div>
              <div>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Using the App (As a Traveler)</Typography>
                  </AccordionSummary>

                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Can I talk to the Walker to tell them what I want to see?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Yes. Once the video goes live, you will be able to talk to your Walker to tell them what you want to see during the walk. You can also click mute.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>How do I edit my profile?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Open the app, click on the hamburger menu icon at the top of the screen. Then click the pencil icon next to your profile image.
           </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >

                        <Typography>How do I end a trip early?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Once your video is live, the control buttons will be at the bottom of the screen. The button to the left will end a trip early.
           </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"

                      >
                        <Typography>How do I add the languages I speak?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          From main Traveler menu select settings and then select languages the languages I speak tab
            </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography >How do I change my notification settings?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          open your app in Traveler Mode. Then Tap Setting tab. Then Tap Frequency tab and select the frequency you want to receive notification. Use the toggle buttons to turn on or off the different Notifications
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>How to get back to my Walker if my trip is interrupted</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          When your trip is interrupted, this could be that someone has ended the walk early, or it could mean you had a bad connection. When this happens, your trip will end and you will only be charged for the actual minutes your video was live. You will also be redirected to the map screen where you can look for the same walker to try and reconnect , or you can choose a different walker.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography >How can I save my trips so that I can watch them later?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Last 3 trips are saved automatically. In version 1.0 Huvr will offer a subscription model that will allow you to see more than 3 trips. You can view your past 3 trips by tapping on Your trips from the main memu.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>How can I see past trips or upcoming scheduled trips?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Open up Huvr app and log in with your phone number. Then Tap &quot;your trips&quot; from main menu. Then Tap either Past or Upcoming . The First 3 trips are saved for free. A subscription model will be available to save more than 3 trips in a future release.
           </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography >How do I select a destination and begin a trip?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Open Huvr and login using your phone number. Then tap a destinations from the list of destinations.Then tap &quot;Huvr there now&quot;. Then select ok to select a Walker from the live Map
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography >How can I send a link to Huvr to my friends?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Coming out in 1.0
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>How can I submit destination&apos;s I want Huvr to add?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Yes. Click the hamburger button in the top left of the app. From the menu, select &quot;Destination Wish List&quot;.There you can add areas you would like to suggest to us.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>I can&apos;t request a walk</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          If you are having trouble connecting to a walker, check your internet connection. If you have at least 4G connection, visit a destination and click on a walkers image.There you will see a button marked &quot;START TRIP&quot;. Once you click this , give the walker a few seconds to accept your trip and BEGIN WALK. If you are having trouble doing the above steps , try closing and restarting your app and give it another try.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>I cut my trip short. Do I have to pay for the whole thing or just what I used?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          As a Traveler, you have the ability to cut your trip short. When you do this, you will only be charged the basic platform access fee plus $1 per minute of time you were live with video. Partial minutes are counted as a full minute.
           </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography >Requesting a specific Walker</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Coming out in 1.0
            </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Schedule a trip in advance</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          On Huvr, we understand that in Beta, there may not be walkers available the moment you get on the app. So we have given you the ability to schedule trips. To do this, simple go to the destination you desire, click on the SCHEDULE TRIPS button at the bottom and fill out the simple form to schedule. You will get a notification 5 minutes before your walk is scheduled to begin.
             </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>What happens if my trip ends before the time runs out?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          We understand that this app is based on several types of common technology, but things happen. If your trip ends before the time runs out, the app will close and you may get an error message. Rest assured that you will only be charged for the actual time you spent on the app with the video live.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>What is the &quot;Make Money Walking&quot; section for?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          This section contains a list of steps that Walkers must go through in order to become a Verified Huvr Walker.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>What is Walker Mode?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Walker mode is for our users who have taken the steps to become a Huvr Walker. These people are gig workers who provide virtual walks for our travelers on the Huvr App.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography >Where can I see my trips?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          From the app, click the hamburger button at the top left of the screen. Then look in the menu for the title &quot;Your Trips&quot;. In Beta, you will be able to see that last three trips you have gone on. In Version 1.0 we will be adding a very affordable membership option that will allow you to access all of your videos and a few other features.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>What do I do if the Huvr App crashes the first time I use it?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          If the Huvr app crashes the first time you use it please restart the Huvr application.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Accordion>
              </div>


              <div>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Virtual Trip issues, Refunds and Cancellations</Typography>
                  </AccordionSummary>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>How can I schedule a trip for the future?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Huvr gives you the ability to schedule trips for up to 7 days in advance. To get started, simply navigate to any destination. Then at the bottom of the screen, click on the &quot;Schedule Trip&quot; button and complete the steps . Our walkers in that area will then be notified of a trip request and one of them will accept it.
          </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>How do scheduled trips work?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Once a scheduled trip is accepted by a walker, both of you will receive a notification 5 minutes before the walk is supposed to begin. As a traveler, you simply need to open you app and wait for the walker to initiate the walk. If the walker does not initiate the walk within 3 minutes of the time, we will assume technical difficulties and will look into the matter immediately.
           </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Payment options</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Making a payment for your virtual experience is easy. Simply add your Credit Card or Debit Card details to your account and you&apos;re ready to go.
           </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>What if the Walker I hired ends the Walk early?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          There are several instances where a walker may end a walk before the time has ended. This might be that the walker mobile device has no battery power remaining. Or the walker felt they were in danger of some kind. If the walker ends a walk early, you will be notified of the reason and you will only be charged for the actual time the video was live.
            </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> Is there a penalty if I cancel my trip 24 hours or more in advance?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          There is no penalty if you cancel your trip 24 hours or more in advance
            </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> Is there a penalty if I cancel my trip  less than 24 hours in advance?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          If a Traveler cancels a trip less than 24 hours in advance the Traveler will be charged a $5.00 cancellation fee and the walker will be sent a notification for the cancelled trip.
            </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> What happens if the Walker cancels my trip lessthan 24 hours in advance?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          If your Walker cancels your trip less than 24 hours in advance Huvr will charge the Walker $5.00 and the Traveler is sent a notification about the cancelled trip with instructions on how to reschedule the trip. The Traveler will receive a $5.00 Credit
            </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> What is the penalty for cancellations made by Traveler or Walker that are less than 3 hours out?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Cancellations made by Traveler or Walker that are less than 3 hours out will be charged a $10 cancellation fee penalty.
            </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Accordion>
              </div>
           
          </div>
        </div>
      </div>
    </div>
  )
}

export default Help;