import { Button } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import * as commonFunctions from '../../utilities/commonFunctions';

const WALKER_ACCEPT_REJECT_BOOKING_REQUESTING = loader('../../graphql/schema/walker/walker-accepted-rejected-booking-request.graphql');
const GET_WALKER_SCHEDULE_TRIPS = loader('../../graphql/schema/walker/walker-scheduleTrips.graphql');
const GET_WALKER_ACCEPTED_SCHEDULE_TRIPS = loader('../../graphql/schema/walker/walker-acceptedScheduleTrips.graphql');
const REJECT_REASON = loader('../../graphql/schema/walker/reject-reason.graphql');
const ACCEPT_SCHEDULE_TRIP_BY_WALKER = loader('../../graphql/schema/walker/accept-walker.graphql');
const CANCELLED_SCHEDULE_TRIP = loader('../../graphql/schema/walker/walker-cancelled-schedule-trip.graphql');
const GET_WALKER_CANCELLED_TRIPS = loader('../../graphql/schema/walker/walker-cancelled.graphql');

const WalkRequest = () => {

    const [status, setStatus] = useState('');
    const [request] = useState('REQUEST');
    const [accepted] = useState('ACCEPTED');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [bookingId, setBookingId] = useState('');
    const [travelerId, setTravelerId] = useState('');
    const [clicked, setClicked] = useState(false);
    const [haserror, setHaserror] = useState('');
    const [size] = useState(16);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [WalkerScheduleTripsData, setWalkerScheduleTripsData] = useState([]);
    const [WalkerAcceptedScheduleTripsData, setWalkerAcceptedScheduleTripsData] = useState([]);
    const [WalkerCancellededScheduleTripsData, setWalkerCancellededScheduleTripsData] = useState([])
    const [rejectReason, setRejectReason] = useState([]);

    const [walkerAcceptRejectBookingRequest] = useMutation(WALKER_ACCEPT_REJECT_BOOKING_REQUESTING);

    const { data: acceptedData } = useQuery(GET_WALKER_ACCEPTED_SCHEDULE_TRIPS, {
        variables: {
            "first": size, "page": page,
            data: { "latitude": latitude, "longitude": longitude, "filter": accepted }
        },
        fetchPolicy: 'network-only',
    });

    const { data: cancelledData } = useQuery(GET_WALKER_CANCELLED_TRIPS, {
        variables: { "first": size, "page": page },
        fetchPolicy: 'network-only',
    });

    const { data: requestData, error } = useQuery(GET_WALKER_SCHEDULE_TRIPS, {
        variables: {
            "first": size, "page": page,
            data: { "latitude": latitude, "longitude": longitude, "filter": request }
        },
        fetchPolicy: 'network-only',
    });

    const { data: rejectreason } = useQuery(REJECT_REASON);

    const { data: accceptWalker } = useMutation(ACCEPT_SCHEDULE_TRIP_BY_WALKER);

    const { data: cancelledWalker } = useMutation(CANCELLED_SCHEDULE_TRIP);

    const handlePage = (pageNo) => {
        let selected = pageNo.selected + 1;
        setPage(selected);
    }

    useEffect(() => {
        if (requestData && requestData.getWalkerScheduleTrips.data.length > 0) {
            setWalkerScheduleTripsData(requestData.getWalkerScheduleTrips.data);
            setPageCount(requestData.getWalkerScheduleTrips.paginatorInfo.lastPage);
        } else {
            if (WalkerScheduleTripsData.length > 0) {
                setWalkerScheduleTripsData([]);
            }
        }

        if (error) {
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
            setHaserror(errorMsg)
        }

        if (acceptedData && acceptedData.getWalkerAcceptedScheduleTrips.data.length > 0) {
            setWalkerAcceptedScheduleTripsData(acceptedData.getWalkerAcceptedScheduleTrips.data);
            setPageCount(acceptedData.getWalkerAcceptedScheduleTrips.paginatorInfo.lastPage);
        } else {
            setWalkerAcceptedScheduleTripsData([]);
        }

        if (cancelledData && cancelledData.getWalkerCancelledTrips.data.length > 0) {
            setWalkerCancellededScheduleTripsData(cancelledData.getWalkerCancelledTrips.data);
            setPageCount(cancelledData.getWalkerCancelledTrips.paginatorInfo.lastPage);
        } else {
            setWalkerCancellededScheduleTripsData([]);
        }

        if (rejectreason && rejectreason.rejectedReason.length > 0) {
            setRejectReason(rejectreason.rejectedReason);
        } else {
            setRejectReason([]);
        }

        if (!navigator.geolocation) {
            console.log('Geolocation is not supported by your browser');
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            });
        }

    }, [requestData,acceptedData,cancelledData,error]);

    const handleAcceptbooking = (data) => {
        walkerAcceptRejectBookingRequest({
            variables: {
                data: {
                    status: status,
                    traveller_id: data.traveler.id,
                    booking_id: data.booking_id
                },
            },
        });
        accceptWalker({
            variables: {
                data: {
                    id: data.id
                }
            }
        });

    }

    const handlepopUp = (data) => {
        setClicked(true);
        setStatus('rejected')
        setTravelerId(data.traveler.id)
        setBookingId(data.booking_id)
        console.log(travelerId);
    }

    const handleRejectbooking = (data) => {
        walkerAcceptRejectBookingRequest({
            variables: {
                data: {
                    status: status,
                    traveller_id: travelerId,
                    booking_id: bookingId,
                    rejected_reason_id: data.id
                },
            },
        });
        cancelledWalker({
            variables: {
                data: {
                    cancelled_user_type: data.user_type,
                    schedule_id: data.id
                }
            }
        });

    }

    const handlepopUpClose = () => {
        setClicked(false);
    }

    return (
        <>
            <div className="traveller-page">
                <div className="traveller-row">
                    <div className="traveller-head">
                        <div className="container">
                            <div className="traveller-head-row">
                                <div className="traveller-head-left">
                                    <h2>Walk Requests</h2>
                                </div>
                                <div className="traveller-head-right">
                                    <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
                    <div className="container">
                        <div className="trips-tab-row">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true" >Requests</a>
                                    <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Accepted</a>
                                    <a className="nav-item nav-link" id="nav-cancelled-tab" data-toggle="tab" href="#nav-cancelled" role="tab" aria-controls="nav-cancelled" aria-selected="false" >CANCELLED</a>
                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    {WalkerScheduleTripsData.length <= 0 && <p className="no-data">No Request Data Found</p>}
                                    {WalkerScheduleTripsData.length > 0 && WalkerScheduleTripsData.length > 0 && WalkerScheduleTripsData.map((d, index) => {
                                        return (
                                            <div className="panel-row" key={index}>
                                                <div className="panel-head">
                                                    <div className="container">
                                                        <div className="destination-body">
                                                            <div className="destination-box" >
                                                                <div className="box-center" >
                                                                    <h4>{d.destination.name || 'N/A'} - {d.destination.location || 'N/A'}</h4>
                                                                    <p>{d.schedule_date || 'N/A'} {d.schedule_time || 'N/A'}</p>
                                                                    <p>{d.traveler.first_name || 'N/A'}</p>
                                                                </div>
                                                                <div className="box-right">
                                                                    <Button variant="contained" color="primary" onClick={() => handleAcceptbooking(d)}>ACCEPT</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {pageCount > 1
                                        ? <div className="search-pagination">
                                            <ul className="pagination pagination-sm m-0 float-right">
                                                <ReactPaginate
                                                    previousLabel={<i className="fa fa-angle-double-left"></i>}
                                                    nextLabel={<i className="fa fa-angle-double-right"></i>}
                                                    breakLabel={<a href="#/">...</a>}
                                                    pageCount={pageCount}
                                                    onPageChange={handlePage}
                                                    breakClassName={"break-me"}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link" />
                                            </ul>
                                        </div>
                                        : ''
                                    }
                                </div>
                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    {WalkerAcceptedScheduleTripsData.length <= 0 && <p className="no-data">No Accepted Data Found</p>}
                                    {WalkerAcceptedScheduleTripsData.length > 0 && WalkerAcceptedScheduleTripsData.map((d, index) => {
                                        return (
                                            <div className="panel-row" key={index}>
                                                <div className="panel-head">
                                                    <div className="container">
                                                        <div className="destination-body">
                                                            <div className="destination-box" >
                                                                <div className="box-center" >
                                                                    <h4>{d.destination.name || 'N/A'} - {d.destination.location || 'N/A'}</h4>
                                                                    <p>{d.schedule_date || 'N/A'} {d.schedule_time || 'N/A'}</p>
                                                                    <p>{d.traveler.first_name || 'N/A'}</p>
                                                                </div>
                                                                <div className="box-right">
                                                                    <Button variant="contained" color="primary" onClick={() => handlepopUp(d)}>REJECT</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {pageCount > 1
                                        ? <div className="search-pagination">
                                            <ul className="pagination pagination-sm m-0 float-right">
                                                <ReactPaginate
                                                    previousLabel={<i className="fa fa-angle-double-left"></i>}
                                                    nextLabel={<i className="fa fa-angle-double-right"></i>}
                                                    breakLabel={<a href="#/">...</a>}
                                                    pageCount={pageCount}
                                                    onPageChange={handlePage}
                                                    breakClassName={"break-me"}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link" />
                                            </ul>
                                        </div>
                                        : ''
                                    }
                                </div>
                                <div className="tab-pane fade" id="nav-cancelled" role="tabpanel" aria-labelledby="nav-cancelled-tab">
                                    {WalkerCancellededScheduleTripsData.length <= 0 && <p className="no-data">No Cancelled Data Found</p>}
                                    {WalkerCancellededScheduleTripsData.length > 0 && WalkerCancellededScheduleTripsData.map((d, index) => {
                                        return (
                                            <div className="panel-row" key={index}>
                                                <div className="panel-head">
                                                    <div className="container">
                                                        <div className="destination-body">
                                                            <div className="destination-box" >
                                                                <div className="box-center" >
                                                                    <h4>{d.ScheduleData.destination.name || 'N/A'} - {d.ScheduleData.destination.location || 'N/A'}</h4>
                                                                    <p>{d.ScheduleData.schedule_date || 'N/A'} {d.ScheduleData.schedule_time || 'N/A'}</p>
                                                                    <p>{d.ScheduleData.traveler.first_name || 'N/A'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {pageCount > 1
                                        ? <div className="search-pagination">
                                            <ul className="pagination pagination-sm m-0 float-right">
                                                <ReactPaginate
                                                    previousLabel={<i className="fa fa-angle-double-left"></i>}
                                                    nextLabel={<i className="fa fa-angle-double-right"></i>}
                                                    breakLabel={<a href="#/">...</a>}
                                                    pageCount={pageCount}
                                                    onPageChange={handlePage}
                                                    breakClassName={"break-me"}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link" />
                                            </ul>
                                        </div>
                                        : ''
                                    }
                                </div>
                                {clicked === true ?
                                    <div className="schedule-popup">
                                        <div>
                                            <div className="schedule-popup-div">
                                                <div>
                                                    <div className="d-flex">
                                                        <h4>Please Select The Reject Reasone</h4>
                                                        <i className="fa fa-times" data-toggle="modal" data-target="#myModal" onClick={handlepopUpClose}></i>
                                                    </div>
                                                </div>
                                                {rejectReason.length > 0 && rejectReason.map((d, index) => {
                                                    return (
                                                        <div key={index} onClick={() => handleRejectbooking(d)}>
                                                            <p onClick={handlepopUpClose}>{d.reject_reason}</p>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WalkRequest;
