import React, { useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import PageLoader from '../../components/layout/ui/page-loader';
import UserUtils from '../../utilities/UserUtils';

const publicUrl = process.env.PUBLIC_URL + '/';
const GET_USER_CARDS = loader('../../graphql/schema/payments/get-user-cards.graphql');
const DELETE_USER_CARDS = loader('../../graphql/schema/payments/delete-card.graphql');

const PaymentMethods = (props) => {

  const { history } = props;

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const { data: userCardsData, loading: loading1 } = useQuery(GET_USER_CARDS, {
    fetchPolicy: 'network-only'
  });

  const postResponseData = (res) => {
    if (res.status === 'CARD_DELETED') {
      setSuccessMessage(res.message);
    } else {
      setErrorMsg(res.message)
    }
    setTimeout(() => {
      setSuccessMessage("");
      setErrorMsg("")
    }, 3000)
  };

  const [deleteCartSubmit, { loading: loading }] = useMutation(DELETE_USER_CARDS, {
    refetchQueries: [{
      query: GET_USER_CARDS,
      fetchPolicy: 'network-only',
    }],
    onCompleted({
      deleteCard: {
        message: message,
        status: status,
      },
    }) {
      postResponseData({ status, message });
    },
    awaitRefetchQueries: true,
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setErrorMsg(formatedErrors);
      setTimeout(() => {
        setErrorMsg("")
      }, 3000)
    },
  });

  const handleClickDelete = (card_id) => {
    deleteCartSubmit({
      variables: {
        "data": card_id
      },
    });
  }

  const handleClose = () => { }

  const deleteCard = (card_id) => {
    confirmAlert({
      closeOnEscape: true,
      title: 'Delete Card',
      message: 'Are you sure? You want to delete this Card.',
      buttons: [
        {
          label: 'Delete',
          onClick: () => handleClickDelete(card_id)
        },
        {
          label: 'Close',
          onClick: () => handleClose()
        }
      ],
    });
  }

  const addCard = () => {
    history.push('/add-card');
  }

  const editCard = (cardData) => {
    UserUtils.setCardId(JSON.stringify(cardData));
    history.push('/update-card');
  }

  return (
    <>
      <div className="traveller-page ">
        {(loading || loading1) && <PageLoader loading={true} />}
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Payment Methods</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>

          <div className="destination-body-page">
            <div className="container">
              <div className="travelle-body">
                <p className="destination-para">This is where you store credit cards to be used for purchases.</p>
                {successMessage !== "" && <p className="success-msg">{`${successMessage}.`}</p>}
                {errorMsg !== "" && <div className="is-invalid">{errorMsg}</div>}

                {userCardsData && userCardsData.getUserCards.length > 0 && userCardsData.getUserCards.map((d, index) => {
                  let defaultCard = "";
                  if (d.default) {
                    defaultCard = 'default-data';
                  }
                  return (
                    <div key={index} className="edit-form-row">
                      <div  className="card-row">
                        <div className="card-row-left">
                          <figure>
                            <img className="payment-card" src={publicUrl + "assets/images/inner-page/master.png"} alt={d.brand} />
                          </figure>
                          <span className="card-number">{`XXXX XXXX XXXX ${d.last4}`}</span>
                        </div>
                        <div className="card-row-right card-btns">
                          <span className={`defualt ${defaultCard}`}>Default</span>
                          <span className="card-menu" data-toggle="modal" data-target="#myModal1" onClick={() => editCard(d)}><i className="fa fa-edit"></i>&nbsp;</span>
                          <span className="card-menu" data-toggle="modal" data-target="#myModal1" onClick={() => deleteCard(d.card_id)}><i className="fa fa-trash"></i></span>
                        </div>
                      </div>
                      <div className="add-card-row bg-white">
                        <div className="add-card-left">
                          <p>Add New Credit Card</p>
                        </div>
                        <div className="add-card-right" onClick={addCard}>
                          <i className="fa fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  )
                })}

              

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* popup start here */}
      <div className="modal fade destination-body-popup" id="myModal">
        <div>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content payment-popup-body">
              <button type="button" className="close" data-dismiss="modal"><i className="la la-times"></i></button>
              <div className="delete-card-row">
                <p>Are you sure want to <span>Delete the card</span></p>
                <p><span className="edit-btn">yes</span><span className="delete-btn">No</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

PaymentMethods.propTypes = {
  history: PropTypes.object.isRequired,
}

const enhance = compose(
  withRouter,
);

export default enhance(PaymentMethods);