import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import PropTypes from "prop-types";
import * as commonFunctions from '../../../utilities/commonFunctions';
import UserUtils from '../../../utilities/UserUtils';
import PageLoader from '../../layout/ui/page-loader';

const USER_REGISTER = loader('./../../../graphql/schema/auth/user-details.graphql');

const RegisterStepTwo = (props) => {
  const { history } = props;
  const apolloClient = useApolloClient();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState({});

  useEffect(() => {

    const phone = UserUtils.getPhoneNumber();
    if (phone !== null) {
      setPhoneNumber(phone);
    } else {
      history.push('/register');
    }

    return () => {
      UserUtils.removePhoneNumber();
    };
  }, []);

  const handlePhoneNumber = (e) => {
    setHasError({});
    setPhoneNumber(e.target.value)
  }

  const handleFirstName = (e) => {
    setHasError({});
    setFirstName(e.target.value)
  }

  const handleLastName = (e) => {
    setHasError({});
    setLastName(e.target.value)
  }

  const handleEmail = (e) => {
    setHasError({});
    setEmail(e.target.value)
  }

  const handlePassword = (e) => {
    setHasError({});
    setPassword(e.target.value)
  }

  const handleConfirmPassword = (e) => {
    setHasError({});
    setConfirmPassword(e.target.value)
  }

  const validateField = (value) => {
    let hasError = false;
    if (commonFunctions.isEmpty(value)) {
      hasError = true;
    }
    return hasError;
  }

  const validateForm = () => {
    const minLength = 8;
    let formIsValid = true;
    let errors = {};

    if (validateField(firstName)) {
      errors['firstName'] = 'First Name is requied.';
      formIsValid = false;
    }

    if (validateField(lastName)) {
      errors['lastName'] = 'Last Name is requied.';
      formIsValid = false;
    }

    if (validateField(phoneNumber)) {
      errors['phoneNumber'] = 'Phone Number is requied.';
      formIsValid = false;
    }

    if (validateField(email)) {
      errors['email'] = 'Email is requied.';
      formIsValid = false;
    } else if (!commonFunctions.validateEmail(email)) {
      errors['email'] = 'Enter valid a email.';
      formIsValid = false;
    }

    if (validateField(password)) {
      errors['password'] = 'Password is requied.';
      formIsValid = false;
    }
    if (!validateField(password) && !commonFunctions.isValidPassword(password, minLength)) {
      errors['password'] = 'Password must be minimum 8 character.';
      formIsValid = false;
    }

    if (validateField(confirmPassword)) {
      errors['confirmPassword'] = 'Confirm Password is requied.';
      formIsValid = false;
    }

    if (!validateField(password) && !validateField(confirmPassword) && password !== confirmPassword) {
      errors['confirmPassword'] = 'Must be same Password and Confirm Password.';
      formIsValid = false;
    }

    setHasError(errors);
    return formIsValid;
  };

  const postSignUp = (resUser) => {
    apolloClient.writeData({ data: { isLoggedIn: true } });
    let error = {};
    if (resUser.status === 'USER_REGISTERED') {
      setSuccessMessage(resUser.message)
      UserUtils.setAccessToken(resUser.access_token);
      UserUtils.setUserID(resUser.user.id);
      setTimeout(() => {
        history.push('/select-destinations');
      }, 1500)
    } else {
      error['failed'] = resUser.message;
      setHasError(error);
    }
  };

  const [submitSignUp, { loading: signUpLoading }] = useMutation(USER_REGISTER, {
    onCompleted({
      register: {
        message: message,
        status: status,
        user: user,
        user_profile_completed: user_profile_completed
      },
    }) {
      postSignUp({ status, message, user, user_profile_completed });
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      let error = {};
      error['failed'] = formatedErrors;
      setHasError(error);
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      submitSignUp({
        variables: {
          "data": {
            "first_name": firstName,
            "last_name": lastName,
            "phone_number": phoneNumber,
            "phone_country_code": "",
            "phone_country_key": "",
            "email": email,
            "password": password,
            "password_confirmation": confirmPassword,
            "time_zone": "",
            "device_token": ""
          },
        },
      });
    }
  }

  return (
    <>
      <div className="signup-container-row profile-details">
        {signUpLoading && <PageLoader loading={signUpLoading} />}
        <div className="signup-row">
          <p className="modal-close" ><Link to="/"><i className="la la-times" /></Link></p>
          <div className="signup-left">
            <div className="signup-cnt">
              <h4 component="h4">Sign Up</h4>
              <p>Huvr is an app connecting virtual Travelers to human Huvr approved Walkers across the globe. They provide individual or group experiences unlike anything on the market today.</p>
              <Link to="/" className="btn"><i className="fa fa-external-link"></i> Learn More</Link>
            </div>
          </div>
          <div className="signup-right">
            <div className="signup-form">
              <div className="form-title">
                <h4 component="h4">Profile Details</h4>
              </div>
              <form onSubmit={onSubmit}>
                {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
                {hasError.failed !== undefined && <p className="is-invalid">{hasError.failed}</p>}
                <div className="form-input-box">
                  <label variant="subtitle2" component="label">Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    className={hasError.phoneNumber !== undefined ? 'is-invalid' : ''}
                    value={phoneNumber}
                    onChange={handlePhoneNumber}
                    disabled
                  />
                </div>
                <div className="form-input-box">
                  <label variant="subtitle2" component="label">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    className={`${hasError.firstName !== undefined ? 'is-invalid' : ''}`}
                    onChange={handleFirstName}
                  />
                  {hasError.firstName !== undefined && <p className="is-invalid">{hasError.firstName}</p>}
                </div>
                <div className="form-input-box">
                  <label variant="subtitle2" component="label">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    className={`${hasError.lastName !== undefined ? 'is-invalid' : ''}`}
                    value={lastName}
                    onChange={handleLastName}
                  />
                  {hasError.lastName !== undefined && <p className="is-invalid">{hasError.lastName}</p>}
                </div>
                <div className="form-input-box">
                  <label variant="subtitle2" component="label">Email</label>
                  <input
                    type="text"
                    name="email"
                    className={`${hasError.email !== undefined ? 'is-invalid' : ''}`}
                    value={email}
                    onChange={handleEmail}
                  />
                  {hasError.email !== undefined && <p className="is-invalid">{hasError.email}</p>}
                </div>
                <div className="form-input-box">
                  <label variant="subtitle2" component="label">Password</label>
                  <input
                    type="password"
                    name="password"
                    className={`${hasError.password !== undefined ? 'is-invalid' : ''}`}
                    value={password}
                    onChange={handlePassword}
                  />
                  {hasError.password !== undefined && <p className="is-invalid">{hasError.password}</p>}
                </div>
                <div className="form-input-box">
                  <label variant="subtitle2" component="label">Confirm Password</label>
                  <input
                    type="password"
                    className={`${hasError.confirmPassword !== undefined ? 'is-invalid' : ''}`}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPassword}
                  />
                  {hasError.confirmPassword !== undefined && <p className="is-invalid">{hasError.confirmPassword}</p>}
                </div>
                <div className="form-btn">
                  <input type="submit" color="inherit" value="Submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

RegisterStepTwo.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance( RegisterStepTwo );