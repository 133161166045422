import React, { useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Slider from "react-slick";

let publicUrl = process.env.PUBLIC_URL + '/';
let imgattr = 'logo';

const BetaDestination = ( props ) => {

  const { destinationsData } = props;

  const sliderRef = useRef();

  const gotoNext = () => {
    sliderRef.current.slickNext()
  }

  const gotoPrev = () => {
    sliderRef.current.slickPrev()
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };

  const sliderMap = () => {
    return destinationsData.length > 0 && destinationsData.map((d, index) => {
      return (
        <div key={index} className="d-list-slider-item">
          
          <div className="single-destinations-list text-center">
            <div className="thumb">
              <img
                className="destination-images"
                src={`${d.image ? d.image : publicUrl + "assets/images/destination-list/1.png"}`}
                alt="list"
              />
            </div>
            <div className="details">
              <h4 className="title">{d.name || 'N/A'}</h4>
              <h5 className="title">{d.location || 'N/A'}</h5>
              <p className="content">{d.description || 'N/A'}</p>
              <Link className="btn btn-yellow" to="/"><img src={publicUrl + "assets/images/huvr-btn.png"} alt={imgattr} className="huvr-btn" /></Link>
            </div>
          </div>
          
        </div>
      )
    })
  }

  return (
    <>
      <div id="beta-destination" className="offer-area pd-top-70 offer-area-row">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title text-center">
                <h2 className="title">Our Beta Destinations</h2>
                <p>
                  <span className="text-block">Forget canned virtual travel and pre-recorded videos.</span>
                  <span className="text-block">Explore the world and experience virtual teleportation in real-time.</span>
                  <span className="text-block">You are in the drivers seat and if you seek, you shall find plenty with Huvr.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="destinations-list-slider-bg">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 offset-xl-1 order-lg-12">
                <div className="destinations-list-slider">
                <Slider  {...sliderSettings} ref={sliderRef}>
                  {sliderMap()}
                  </Slider>
                </div>
              </div>
              <div className="col-lg-2 align-self-center order-lg-11">
                <div className="container">
                  <div className="destinations-slider-controls">
                    <div className="slider-nav tp-control-nav" />
                    {/*slider-nav*/}
                    <div className="tp-slider-extra slider-extra">
                      <div className="text">
                        <span className="first">01 </span>
                        <span className="last">{destinationsData.length && destinationsData.length}</span>
                      </div>
                      <button onClick={()=>gotoNext()}>Next</button>
                      <button onClick={()=>gotoPrev()}>Previous</button>
                      {/*text*/}
                      <div className="d-list-progress" role="progressbar" aria-valuemin={0} aria-valuemax={100}>
                        <span className="slider__label sr-only" />
                      </div>
                    </div>
                    {/*slider-extra*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

BetaDestination.propTypes = {
  destinationsData: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance( BetaDestination );