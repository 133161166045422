import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import ReactFlagsSelect from 'react-flags-select';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useMutation,useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Select from '@material-ui/core/Select';

const UPDATE_WALKER_PROFILE = loader('../../graphql/schema/walker/update-walker-profile.graphql');
const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');

const WalkerProfile= (props) => {
  const {history} =props;
  const [hasError, setHasError] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [destinations,setDestinations] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [uname, setUserName] = useState('');
  console.log('equipment------',equipment)

  const handleDestinations = (values) => {
    setDestinations([values.destination_one, values.destination_two, values.destination_three]);
  }

  const handleChangeequipments = (e) => {
    setEquipment(e.target.value);
  }
  const { data: myDetails } = useQuery(MY_DETAILS, {
    onCompleted(){
    setUserName(`${myDetails && myDetails.me.first_name}`+' '+`${myDetails && myDetails.me.last_name}`);
     
    },
   });

  const [updateMyAccount] = useMutation(UPDATE_WALKER_PROFILE, {
    onCompleted() {
      history.push('/select-destinations');
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  const validate = (values) => {

  const errors = {};

    if (!values.city_or_town) {
      errors.city_or_town = 'Please Enter City Or Town';
    }

    if (!values.state_or_province) {
      errors.state_or_province = 'Please Enter State Or Province';
    }
    if (!countryCode) {
      errors.country = 'Country is required.';
    }
    if (!values.destination_one) {
      errors.destination_one = 'Please Enter destination1';
    }
    if (!values.destination_two) {
      errors.destination_two = 'Please Enter destination2';
    }

    if (!values.destination_three) {
      errors.destination_three = 'Please Enter destination3';
    }
    if (!values.languages) {
      errors.languages = 'Please Enter Languages';
    }

    if (!values.description) {
      errors.description = 'Please Enter Walker Description';
    }
      return errors
  }

  const onSubmit = (values) => {
    setDestinations([values.destination_one,values.destination_two,values.destination_three]);
    
    console.log("values----", values)
    updateMyAccount({
      variables: {
        data: {
          city_or_town: values.city_or_town,
          state_or_province: values.state_or_province,
          country: countryCode,
          top_three_destinations: [values.destination_one, values.destination_two, values.destination_three],
          languages: values.languages,
          equipments: equipment,
          description: values.description
        },
      },
    });
  }

  return (
    <div className="traveller-page">
      <div className="traveller-row">
        <div className="traveller-head">
          <div className="container">
            <div className="traveller-head-row">
              <div className="traveller-head-left">
                <h2>My Walker Profile</h2>
              </div>
              <div className="traveller-head-right">
                <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="travelle-body">
            <div className="edit-form-row edit-form-row-new">
              {hasError && <p className="is-invalid">{hasError}</p>}
              <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={{
                  full_name: uname,
                  no_of_walks: myDetails && myDetails.me.walker_profile.no_of_walks,
                }}
              render={({ handleSubmit, submitting }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-in">
                      <span> Name</span>
                      <Field
                        id="full_name"
                        type="text"
                        name="full_name"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                        disabled
                        defaultValue={uname}
                    />
                    </div>
                    <div className="form-in">
                      <span>CITY or TOWN</span>
                      <Field
                        id="city_or_town"
                        placeholder=""
                        type="type"
                        name="city_or_town"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className="form-in">
                      <span>STATE or PROVINCE</span>
                      <Field
                        id="state_or_province"
                        placeholder=""
                        type="type"
                        name="state_or_province"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div>
                      <div>COUNTRY</div>
                      <Field name="country">
                        {({ input }) => (
                          <div>
                            <ReactFlagsSelect
                              {...input}
                              searchPlaceholder=""
                              searchable
                              selected={countryCode}
                              onSelect={code => setCountryCode(code)}
                            />
                          </div>
                        )}
                      </Field>
                    </div>

                    <div>TOP 3 NEAREST HUVR DESTINATIONS</div>
                    <div className="form-in">
                      <span>Destination1</span>
                      <Field
                        id="destination_1"
                        placeholder="DESTINATION1"
                        type="type"
                        name="destination_one"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                        value={destinations}
                        onChange={handleDestinations}
                      />
                    </div>
                    <div className="form-in">
                      <span>Destination2</span>
                      <Field
                        id="destination_2"
                        placeholder="DESTINATION2"
                        type="type"
                        name="destination_two"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                        value={destinations}
                        onChange={handleDestinations}
                      />
                    </div>
                    <div className="form-in">
                      <span>Destination3</span>
                      <Field
                        id="destination_three"
                        placeholder="DESTINATION3"
                        type="type"
                        name="destination_three"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                        value={destinations}
                        onChange={handleDestinations}
                      />
                    </div>
                      <div className="form-in">
                      <span>Number Of Walks</span>
                      <Field
                        id="no_of_walks"
                        placeholder=""
                        type="type"
                        name="no_of_walks"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                        disabled
                        defaultValue={myDetails && myDetails.me.walker_profile.no_of_walks}
                      />
                    </div>
                    <div className="form-in">
                      <span>Language I Speak</span>
                      <Field
                        id="languages"
                        placeholder=""
                        type="type"
                        name="languages"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div>Equipment I Have
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={equipment}
                    onChange={handleChangeequipments}
                    >
                    <option value='1'>MobilePhone</option>
                    <option value='2'>Bicycle</option>
                    <option value='3'>MotorScoter</option>
                    <option value='4'>Drone</option>
                    <option value='5'>Stabilizer</option>
                    <option value='6'>Go-PRO</option>
                  </Select>
                </div>
                  
                    <div className="form-in form-textarea">
                      <span>Walker Description</span>
                      <Field
                        id="description"
                        placeholder="Walker Description"
                        type="type"
                        name="description"
                        multiline
                        rows={7}
                        rowsmax={20}
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>

                    <div className="form-btn">
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={submitting}
                      >
                        Update
                    </Button>
                    </div>
                    <div className="form-btn">
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
WalkerProfile.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(WalkerProfile);
