import React from 'react';

const publicUrl = process.env.PUBLIC_URL + '/';

const Review = () => {

  return (
    <>
      <div className="client-review-area client-review-area-home client-review-area-row pd-top-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="thumb wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
                <div className="client-review-thumb">
                  <img src={publicUrl + "assets/images/travel-map.png"} alt="review" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-client-review wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                <p className="sub-title">A spotlight On</p>
                <h3 className="location-name">Huvr</h3>
                <p>Huvr is an app connecting virtual Travelers to human &ldquo;Huvr approved&ldquo; Walkers across the globe. Our Certified and Trained Huvr Walkers provide individual or group experience unlike anything on the market today.</p>
                <p>We are currently in Beta mode and have about 20 destinations in 10 countries available for virtual travel experiences...but we add destinations at the time.</p>
                <p className="travel-head">Our Mission is Simple:</p>
                <p>It is to ignite global opportunity to communities and economies at scale. We do this by creating jobs that supply unrivaled experiences to inspired travelers, all in real-time via our virtual travel app.</p>
                <div className="media">
                  <div className="media-left">
                    <img src={publicUrl + "assets/images/testimonial-img1.png"} alt="client" />
                  </div>
                  <div className="media-body">
                    <h6>Jaime-Lee Fraser</h6>
                    <p>Chief Marketing Officer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Review;
