import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import ScrollIntoView from 'react-scroll-into-view';
import PropTypes from 'prop-types';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Rating from '@material-ui/lab/Rating';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Switch from 'react-switch';
import userUtils from '../../utilities/UserUtils';

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const GET_AUTH_INFO = loader('../../graphql/schema/auth/auth-info.graphql');
const WALKER_TRAVELER_TOGGLE = loader('../../graphql/schema/auth/walker-traveler-toggle.graphql');

const publicUrl = process.env.PUBLIC_URL + '/'
const imgattr = 'logo'

const Header = (props) => {

	const { history } = props;

	const apolloClient = useApolloClient();

	const [drawer, setDrawer] = useState(false);
	const [addClass, setAddClass] = useState('');

	const { data } = useQuery(GET_AUTH_INFO, {
		onCompleted() {
			const token = localStorage.getItem('app-token');
			if (token) {
				apolloClient.writeData({ data: { isLoggedIn: true } });
			}
		},
	});

	const { data: toggleMode } = useQuery(WALKER_TRAVELER_TOGGLE);

	const { data: myDetails } = useQuery(MY_DETAILS);

	useEffect(() => {
		const pageName = props.location.pathname;
		if (pageName !== '/' || pageName !== '/login' || pageName !== '/verify-login' || pageName !== '/register') {
			setAddClass('inner-page');
		}
		if(pageName === '/'){
			setAddClass('');
		}
 
	}, [addClass]);

	const handleChange = (checked) => {
		apolloClient.writeData({ data: { isWalkerTraveller: checked } });
	}

	const toggleDrawer = () => {
		setDrawer(!drawer)
	};

	const closeDrawer = () => {
		setDrawer(false)
	}

	const handleSignOut = () => {
		setDrawer(false);
		userUtils.removeAccessToken();
		apolloClient.resetStore();
		history.push('/');
	};

	const handleSettings = () => {
		history.push('/settings');
	};

	const handleEditAccount = () => {
		history.push('/edit-account');
	};

	const handleWalkerProfile = () => {
		history.push('/walker-profile');
	};

	const handleMakeMoneyWalking = () => {
		history.push('/make-money-for-walker');
	};

	const handlePayoutAccount = () => {
		history.push('/payout-account');
	};

	const handleCompletedTrips = () => {
		history.push('/completed-trips-for-walker');
	};

	const handlePaymentMethods = () => {
		history.push('/payment-methods');
	};

	const handleWalkerKits = () => {
		history.push('/walker-kit');
	};

	const handleRatings = () => {
		history.push('/ratings');
	};

	const handleManageMembership = () => {
		history.push('/membership');
	};

	const handleDestinationWishlist = () => {
		history.push('/destination-wishlist');
	};

	const handleYourTrips = () => {
		history.push('/your-trips');
	};

	const handleHelp = () => {
		history.push('/help');
	};

	return (
		<nav className={`navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top ${addClass}`}>
			<div className="container nav-container">
				<div className="responsive-mobile-menu">
					<div className="mobile-logo">
						<Link to="/">
							<img src={publicUrl + "assets/images/sticky-logo.png"} alt={imgattr} />
						</Link>
					</div>
					<button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#tp_main_menu" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggle-icon mobile-bar">
							<span>Menu <i className="la la-angle-down"></i></span>
							{/* <span className="line" />
							<span className="line" />
							<span className="line" /> */}
						</span>
					</button>
					<div className="nav-right-content">
						<ul className="pl-0">
							<li className="top-bar-btn-booking">
								<Link className="btn btn-yellow" to="/"><img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" /></Link>
							</li>
							<li className="search">
								<i className="ti-search" />
							</li>
							{/* <li className="notification">
								<a className="signUp-btn" href="/#">
									<i className="fa fa-user-o" />
								</a>
							</li> */}
						</ul>
					</div>
				</div>
				<div className="collapse navbar-collapse" id="tp_main_menu">
					<div className="logo-wrapper desktop-logo">
						<Link to="/" className="main-logo">
							<img src={publicUrl + "assets/images/logo.png"} alt="logo" />
						</Link>
						<Link to="/" className="sticky-logo">
							<img src={publicUrl + "assets/images/sticky-logo.png"} alt="logo" />
						</Link>
					</div>
					<ul className="navbar-nav">

						<li className="menu-item-has-children">
							<ScrollIntoView selector="#home">
								<Link to="/">Home</Link>
							</ScrollIntoView>
						</li>

						<li>
							<ScrollIntoView selector="#about-huvr">
								About Huvr
							</ScrollIntoView>
						</li>

						<li className="menu-item-has-children">
							<ScrollIntoView selector="#beta-destination">
								Beta Destinations
							</ScrollIntoView>
						</li>

						<li className="menu-item-has-children">
							<ScrollIntoView selector="#our-blog">
								Our Blog
							</ScrollIntoView>
						</li>

						<li className="menu-item-has-children desktop-hide">
							<ScrollIntoView selector="#our-blog">
								<Link to="/login">Login</Link>
							</ScrollIntoView>
						</li>

						<li className="menu-item-has-children desktop-hide">
							<ScrollIntoView selector="#our-blog">
								<Link to="/register">Sign Up</Link>
							</ScrollIntoView>
						</li>


					</ul>
				</div>
				<div className="nav-right-content nav-right-content-new">
					<ul>
						<li className="mobile-hide">
							<Link className="btn btn-yellow" to="/"><img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" /></Link>
						</li>
						{!data.isLoggedIn &&
							(<React.Fragment>
								<li className="menu-item-has-children mobile-hide">
									<Link to="/login">Login</Link>
								</li>
								<li className="menu-item-has-children mobile-hide">
									<Link to="/register">Sign Up</Link>
								</li>
							</React.Fragment>
							)}
						{data.isLoggedIn && <li className="menu" onClick={toggleDrawer}>
							<i className="fa fa-bars" ></i>
						</li>}
						<li className="search mobile-hide">
							<i className="ti-search" />
						</li>
					</ul>

					<SwipeableDrawer
						open={drawer}
						docked={false}
						className="app-drawer"
						onRequestChange={closeDrawer}
						onClose={closeDrawer}
						onOpen={toggleDrawer}
					>
						<div className="swipebar-row">
							<p className="mobile-icon"><i className="la la-times" onClick={closeDrawer}></i></p>
							{!data.isLoggedIn && <p className="login-text">Login/Signup to start your virtual journey</p>}
							<div>
								{data.isLoggedIn &&
									<div className="profile-box-row">
										<div className={`profile-row ${toggleMode.isWalkerTraveller !== true ? 'profile-row-two' : ''}`}>
											<div className="toggle-row">
												<Switch
													onChange={handleChange}
													checked={toggleMode.isWalkerTraveller}
												/>
												<span className="toggle-title">{toggleMode.isWalkerTraveller === false ? 'Traveler' : 'walKer'}</span>
											</div>
											{toggleMode.isWalkerTraveller === false ?
												(<div className="profile-row-flex">
													<div className="profile-row-left">
														<figure>
															<img src={myDetails && myDetails.me.avatar ? myDetails.me.avatar : 'assets/images/testimonial-img1.jpg'} alt="profile" />
														</figure>
													</div>
													<div className="icon-row">
														<div className="edit_icon" onClick={handleEditAccount}><i className="la la-edit"></i></div>
														<div className="edit_icon" onClick={closeDrawer}><i className="la la-times"></i></div>
													</div>
													<div className="profile-row-right">
														<h5>
															{myDetails && myDetails.me.first_name}
															{' '}
															{myDetails && myDetails.me.last_name}
														</h5>
														<div className="flex-div">
															<span className="rating-star">&nbsp;</span>


														</div>
													</div>
												</div>
												)
												:
												(<div className="profile-row-flex" >
													<div className="profile-row-left">
														<figure>
															<img src={myDetails && myDetails.me.avatar ? myDetails.me.avatar : 'assets/images/testimonial-img1.jpg'} alt="profile" />
														</figure>
													</div>
													<div className="icon-row icon-row-new">
														<div className="edit_icon" onClick={closeDrawer}><i className="la la-times"></i></div>
													</div>
													<div className="profile-row-right">
														<h5>
															{myDetails && myDetails.me.first_name}
															{' '}
															{myDetails && myDetails.me.last_name}
														</h5>
														<div className="flex-div">
															<Rating name="read-only" value={myDetails && myDetails.me.over_all_rating} readOnly />
															{/* <span className="rating-star">
																<span>
																	4.96
																	<i className="fa fa-star" />
																</span>
															</span> */}
														</div>
													</div>
												</div>
												)}
										</div>

										<div className="header-list">
											<div className="toggle-one">
												<List>
													{/* <ListItem className="active" button>
														<ListItemText primary="Traveler" />
													</ListItem> */}
													{toggleMode.isWalkerTraveller === true && <>
														<ListItem className="submenuList" button onClick={handleEditAccount}>
															<ListItemText primary="Edit My Account" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handleWalkerProfile} >
															<ListItemText primary="My Walker Profile" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handleMakeMoneyWalking}>
															<ListItemText primary="Make money walking" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handlePayoutAccount}>
															<ListItemText primary="Payout Account" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handleCompletedTrips}>
															<ListItemText primary="Completed Trips" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handlePaymentMethods}>
															<ListItemText primary="Payment Methods" />
														</ListItem>
														<ListItem className="submenuList" button>
															<ListItemText primary="Earnings" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handleWalkerKits}>
															<ListItemText primary="Walker Kits" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handleRatings}>
															<ListItemText primary="Ratings" />
														</ListItem>
													</>}
													{toggleMode.isWalkerTraveller === false && <>

														<ListItem className="submenuList" button onClick={handleManageMembership}>
															<ListItemText primary="Manage Membership (coming soon)" />
														</ListItem>
														{/* <ListItem className="submenuListTwo" button>
														<ListItemText primary="Archived Trips" />
													</ListItem> */}
														<ListItem className="submenuListTwo" button>
															<ListItemText primary="Join A Group Trip" />
														</ListItem>
														<ListItem className="submenuListTwo" button>
															<ListItemText primary="Schedule Your Favorite Walker" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handleMakeMoneyWalking}>
															<ListItemText primary="Make money walking" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handlePaymentMethods}>
															<ListItemText primary="Payment Methods" />
														</ListItem>
														<ListItem className="submenuList" button>
															<ListItemText primary="Send a Gift Card" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handleDestinationWishlist}>
															<ListItemText primary="Destination Wish List" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handleYourTrips}>
															<ListItemText primary="Your Trips" />
														</ListItem>
													</>}
													<ListItem className="submenuList" button onClick={handleHelp}>
														<ListItemText primary="Help" />
													</ListItem>
													<ListItem className="submenuList" button onClick={handleSettings}>
														<ListItemText primary="Settings" />
													</ListItem>
													<ListItem className="submenuList" button onClick={handleSignOut}>
														<ListItemText primary="Sign out" />
													</ListItem>
												</List>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</SwipeableDrawer>
				</div >
			</div>
		</nav >
	)
};

Header.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const enhance = compose(
	withRouter,
);

export default enhance(Header);
