import React from 'react';
import { Link } from 'react-router-dom';

const publicUrl = process.env.PUBLIC_URL + '/';

const AddSection = () => {
  return (
    <>
      <div className="ads-area pd-top-90 viaje-go-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9">
              <Link className="ads-thumb" to="/">
                <img src={publicUrl + "assets/images/blog/blog-banner.png"} alt="ads" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddSection;
