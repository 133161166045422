
import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { format, utcToZonedTime } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';
import Slider from '@material-ui/core/Slider';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import UserUtils from '../../utilities/UserUtils';
import PageLoader from '../../components/layout/ui/page-loader';

const GET_TRIP_RATE = loader('../../graphql/schema/destinationView/get-trip-rate.graphql');
const GET_SAVE_SCHEDULE_TRIP = loader('../../graphql/schema/walker/save-schedule-trip.graphql');

const ScheduleTrip = (props) => {

  const { history } = props;

  const [destination, setDestination] = useState('');
  const [destinationId, setDestinationId] = useState(0);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [currentTime, setCurrentTime] = useState();
  const [destinationTimeZone, setDestinationTimeZone] = useState();
  const [currentTimeZoneName, setCurrentTimeZoneName] = useState('');
  const [destTimeZoneName, setDestTimeZoneName] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [tripRate, setTripRate] = useState([]);
  const [tripRateActive, setTripRateActive] = useState(0);
  const [customTripvalue, setCustomTripValue] = useState(1);
  const [customTrip, setCustomTrip] = useState(false);

  const handleCustomTrip = (event, newValue) => {
    setCustomTripValue(newValue);
  };

  const { data, loading } = useQuery(GET_TRIP_RATE, {
    variables: { "data": destinationId },
    fetchPolicy: 'network-only',
  });

  const [saveScheduleTrip] = useMutation(GET_SAVE_SCHEDULE_TRIP);

  useEffect(() => {
    const des_data = JSON.parse(UserUtils.getDestinationData());
    if (des_data !== null && des_data !== undefined) {
      setTimeZone(des_data);
      setDestinationId(des_data.id);
      setDestination(des_data);
      // TO SET THE MAX DATE RANGE
      let date = new Date();
      date.setDate(date.getDate() + 7);
      setMaxDate(date);
      if (data) {
        setTripRate(data.getTripRate);
      }
    } else {
      history.push('/select-destinations');
    }

  }, [data]);

  const seconds_with_leading_zeros = () => {
    return /\((.*)\)/.exec(new Date().toString())[1];
  }

  const handleDateChange = (date) => {
    setScheduleDate(date);
  };

  const handleTimeChange = async (time) => {
    // CURRENT TIME ZONE
    setSelectedTime(time);
    const curr_time = await format(new Date(time), 'HH:mm a');
    setCurrentTime(curr_time);
    setCurrentTimeZoneName(seconds_with_leading_zeros());
    // DESTINATION TIME ZONE
    const desTimeZone = destination.time_zone;
    if (desTimeZone) {
      const des_tz_dt = await utcToZonedTime((time || new Date()), desTimeZone);
      format(des_tz_dt, 'yyyy-MM-dd HH:mm:ss zzz', { timeZone: desTimeZone });
      const des_cal_tz = await format(des_tz_dt, 'HH:mm a', { timeZone: desTimeZone });
      const des_tz_name = await format(des_tz_dt, 'zzzz', { timeZone: desTimeZone, locale: enGB });
      setDestTimeZoneName(des_tz_name);
      setDestinationTimeZone(des_cal_tz);
    }
  }

  const setTimeZone = async (d_data) => {
    // DEFAULT CURRENT TIME ZONE
    const date = new Date();
    const curr_time = await format(new Date(date), 'HH:mm a');
    setCurrentTime(curr_time);
    // TO CURRENT LOCATION SYSTEM TIME ZONE NAME
    setCurrentTimeZoneName(seconds_with_leading_zeros());
    // DEFAULT DESTINATION TIME ZONE
    const desTimeZone = d_data.time_zone;
    if (desTimeZone) {
      const des_tz_dt = await utcToZonedTime(date, desTimeZone);
      format(des_tz_dt, 'yyyy-MM-dd HH:mm:ss zzz', { timeZone: desTimeZone });
      // TO DISPLAY DESTINATION TIME
      const des_cal_tz = await format(des_tz_dt, 'HH:mm a', { timeZone: desTimeZone });
      // TO DISPLAY THE DESTINATION LOCAL TIME ZONE NAME
      const des_tz_name = await format(des_tz_dt, 'zzzz', { timeZone: desTimeZone, locale: enGB });
      setDestTimeZoneName(des_tz_name);
      setDestinationTimeZone(des_cal_tz);
    }
  }

  const selectTripRate = (trip_rate, index) => {
    console.log(trip_rate, index)
    setCustomTripValue(1);
    setCustomTrip(false);
    setTripRateActive(index + 1);
  }

  const handleChangeCustomTrip = () => {
    setTripRateActive(0);
    setCustomTripValue(1);
    setCustomTrip(!customTrip)
  }

  const requestScheduleTrip = () => {
    saveScheduleTrip({
      variables: {
        data: {
          hour_format: 24,
          destination_id: destinationId,
          schedule_date: scheduleDate,
          schedule_time: selectedTime,
          time_zone: destTimeZoneName,
          schedule_minute: customTripvalue,
          schedule_price: customTripvalue * 5,
          epoch:"122344441125",
        }
      }
    })
  }

  return (
    <>
      <div className="traveller-page">
        {loading && <PageLoader loading={loading} />}
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Schedule a virtual trip <span className="title-small">to Hyderabad, India</span></h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="schedule-trip-body2">
              <div>
                <div className="schedule-pick-row">
                  <div className="schedule-pick-title schedule-trip-page">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          clearable
                          minDate={new Date() || undefined}
                          maxDate={maxDate || undefined}
                          margin="normal"
                          id="date-picker-dialog"
                          label=""
                          format="MM/dd/yyyy"
                          value={scheduleDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <h4>Pick a Date</h4>
                    <p>(up to 7 days in the future)</p>
                  </div>
                  <div className="schedule-pick-cnt">
                    <h4>{format(new Date(scheduleDate), 'iiii')}</h4>
                    <p>{format(new Date(scheduleDate), 'LLLL dd, yyyy')}</p>
                  </div>
                </div>
                <div className="schedule-pick-row">
                  <div className="schedule-pick-title schedule-trip-page">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardTimePicker
                          clearable
                          margin="normal"
                          id="time-picker"
                          label="Time picker"
                          value={selectedTime}
                          onChange={handleTimeChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <h4>Pick a Time</h4>
                  </div>
                  <div className="schedule-pick-time">
                    <p>MY TIMEZONE <span>{currentTimeZoneName || 'N/A'}</span></p>
                    <h4>{(currentTime && currentTime || 'N/A')}</h4>
                    <h6>DESTINATION TIME &nbsp;<span> {destTimeZoneName || 'N/A'}</span></h6>
                    <h4>{(destinationTimeZone && destinationTimeZone) || 'N/A'}</h4>
                  </div>
                </div>
                <div className="schedule-pick-row">
                  <h4 className="package-title">Pick a Package</h4>

                  {tripRate.map((d, index) => {
                    return (
                      <>
                        <div key={index} className={`price-btn ${tripRateActive === (index + 1) ? 'selected-rate' : ''}`} onClick={() => selectTripRate(d, index)}>{`${d.minutes} Minuts for $${d.price}`}</div>
                      </>
                    )
                  })}

                  <div className={`price-btn price-btn-last ${customTrip ? 'custom-selected-rate' : ''}`} onClick={handleChangeCustomTrip}>Custom Length</div>
                  {customTrip &&
                    <Grid item xs>
                      <Slider value={customTripvalue} onChange={handleCustomTrip} aria-labelledby="continuous-slider" />
                      <p>{`${customTripvalue} Minutes for $${parseFloat(customTripvalue) * 5}`}</p>
                    </Grid>
                  }
                </div>
                <div className="schedule-pick-row">
                  <div className="schedule-pick-btn">
                    <button className="btn btn-yellow" onClick={requestScheduleTrip}>Request Scheduled Trip</button>
                  </div>
                  <h4 className="package-title">Important</h4>
                  <div className="schedulePickRowList">
                    <p>1. Your scheduled trip will be in <span className="text-red">Pending</span> status <span className="text-red">Until</span> the request has been <span className="text-red">Accepeted</span> by a walker.</p>
                    <p>2. Your will be notified once your request has been accepted.</p>
                    <p>You can check the status of your scheduled trips in the Upcoming section of <Link to="/">Your Trips</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ScheduleTrip.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(ScheduleTrip);
