import Layout from './../components/layout/Layout';
import Home from '../screen/home/home';
import Login from '../components/auth/login/login';
import VerifyLogin from '../components/auth/login/verify-login';
import SignupStepOne from '../components/auth/signup/signup-step-one';
import SignupStepTwo from '../components/auth/signup/signup-step-two';
import VerifyOtp from '../components/auth/signup/verify-otp';
import EditAccount from '../components/auth/edit-account/edit-account';
import SelectDestinations from '../screen/traveller/select-destination';
import Membership from '../screen/traveller/member-ship';
import YourTrips from '../screen/traveller/your-trip';
import DestinationWishList from '../screen/traveller/destination-wishlist';
import WelcomeBack from '../screen/traveller/welcome-back';
import ScheduleWalker from '../screen/traveller/schedule-walker';
import ScheduleTrip from '../screen/traveller/schedule-trip';
import WalkerTerms from '../screen/traveller/walker-terms';
import ChangePassword from '../components/auth/change-password/change-password';
import WalkerKit from '../screen/walker-kit/walker-kit';
import WalkerTraining from '../screen/walker-training/walker-training';
import WalkerRating from '../screen/walker-rating/walker-rating';
import Settings from '../screen/settings/settings';
import PaymentMethods from '../screen/payments/paymentMethods';
import AddCard from '../screen/payments/addCard';
import UpdateCard from '../screen/payments/update-card';
import YourTrip from '../screen/traveller/your-trip';
import TravellerUpcomingWalks from '../screen/traveller/traveller-upcomingWalks';
import TravellerCancelledTrips from '../screen/traveller/traveller-cancelledTrips';
import Ratings from '../screen/rating-details/ratings';
import WalkRequest from '../screen/walker/walk-request';
import MakeMoneyForWalker from '../screen/make-money-walker/make-moneyforWalker';
import CreateDestinationwishlist from '../screen/traveller/create-destinationwishlist';
import CreateNewDestination from '../screen/traveller/create-newDestination';
import ShowAllDestinations from '../screen/traveller/show-allDestinations';
import WalkerProfile from '../screen/walker/walker-profile';
import Help from '../screen/help/help';
import ComingSoonPage from '../screen/coming-soon-page/coming-soon-page';
import forgotPassword from '../components/auth/forgot-password/forgot-password';
import updateForgotPassword from '../components/auth/forgot-password/update-forgot-password';
import WalkerHelp from '../screen/walker-help/walker-help';
import CompletedTripsForWalker from '../screen/traveller/completed-trips-for-walker/completed-trips-for-walker';
import ShowWalkerOnMap from '../screen/walker/show-walker-on-map';
import VideoCall from '../screen/video-call/video-call';
import PayoutAccount from '../screen/payout-account/payout-account';
import BackGroundCheckTraveler from '../screen/background-check/back-ground-check-traveler';
import BackGroundCheckWalker from '../screen/background-check/back-ground-check-walker';
import BeginBackgroundCheckWalker from '../screen/background-check/begin-background-check-walker';
import BeginBackgroundCheckTraveler from '../screen/background-check/begin-background-check-traveler';
import MakeMoneyForTraveler from '../screen/make-money-walker/make-money-traveler';
import WalkerTermsTraveler from '../screen/traveller/walker-terms-traveler';


export const publicRoutes = [
  {
    key: '/',
    exact: true,
    path: '/',
    component: Home,
    layout: Layout,
  },
  {
    key: 'login',
    exact: true,
    path: '/login',
    component: Login,
    layout: Layout,
  },
  {
    key: 'verifyLogin',
    exact: true,
    path: '/verify-login',
    component: VerifyLogin,
    layout: Layout,
  },
  {
    key: 'register',
    exact: true,
    path: '/register',
    component: SignupStepOne,
    layout: Layout,
  },
  {
    key: 'verifyOtp',
    exact: true,
    path: '/verify-otp',
    component: VerifyOtp,
    layout: Layout,
  },
  {
    key: 'registerProfileDetails',
    exact: true,
    path: '/register-profile-details',
    component: SignupStepTwo,
    layout: Layout,
  },
  {
    key: 'editAccount',
    exact: true,
    path: '/edit-account',
    component: EditAccount,
    layout: Layout,
  },

  {
    key: 'membership',
    exact: true,
    path: '/membership',
    component: Membership,
    layout: Layout,
  },
  {
    key: 'yourTrips',
    exact: true,
    path: '/your-trips',
    component: YourTrips,
    layout: Layout,
  },
  {
    key: 'completedTripsForWalker',
    exact: true,
    path: '/completed-trips-for-walker',
    component: CompletedTripsForWalker,
    layout: Layout,
  },

  {
    key: 'destinationWishList',
    exact: true,
    path: '/destination-wishlist',
    component: DestinationWishList,
    layout: Layout,
  },
  {
    key: 'welcomeBack',
    exact: true,
    path: '/welcome-back',
    component: WelcomeBack,
    layout: Layout,
  },
  {
    key: 'scheduleWalker',
    exact: true,
    path: '/schedule-walker',
    component: ScheduleWalker,
    layout: Layout,
  },
  {
    key: 'walkerKit',
    exact: true,
    path: '/walker-kit',
    component: WalkerKit,
    layout: Layout,
  }, 
  {
    key: 'WalkerTraining',
    exact: true,
    path: '/walker-training',
    component: WalkerTraining,
    layout: Layout,
  },
  {
    key: 'walkerTerms',
    exact: true,
    path: '/walker-terms',
    component: WalkerTerms,
    layout: Layout,
  },
  {
    key: 'ChangePassword',
    exact: true,
    path: '/change-password',
    component: ChangePassword,
    layout: Layout,
  },
  {

    key: 'CreateNewDestinatination',
    exact: true,
    path: '/create-new-destination',
    component: CreateNewDestination,
    layout: Layout,
  },
  {
    key: 'CreateDestinationWishList',
    exact: true,
    path: '/create-destinationwish-list',
    component: CreateDestinationwishlist,
    layout: Layout,
  },
  {
    key: 'ShowAllDestinations',
    exact: true,
    path: '/show-all-destinations',
    component: ShowAllDestinations,
    layout: Layout,
  },
   {
    key: 'YourTrips',
    exact: true,
    path: '/your-trips',
    component: YourTrip,
    layout: Layout,
  },
  
  {
    key: 'travellerUpcomingWalks',
    exact: true,
    path: '/traveller-upcoming-walks',
    component: TravellerUpcomingWalks,
    layout: Layout,
  },
  {
    key: 'travellerCancelledTrips',
    exact: true,
    path: '/traveller-cancelled-trips',
    component: TravellerCancelledTrips,
    layout: Layout,
  },
  {
    key: 'ratingDetails',
    exact: true,
    path: '/ratings',
    component: Ratings,
    layout: Layout,
  },
  {
    key: 'walkRequest',
    exact: true,
    path: '/walk-requests',
    component: WalkRequest,
    layout: Layout,
  },
  {
    key: 'WalkerRating',
    exact: true,
    path: '/walker-rating',
    component: WalkerRating,
    layout: Layout,
  },
  {
    key: 'WalkerRating',
    exact: true,
    path: '/walker-rating',
    component: WalkerRating,
    layout: Layout,
  },
  {
    key: 'videoCall',
    exact: true,
    path: '/video-call',
    component: VideoCall,
    layout: Layout
  },
  {
    key: 'Settings',
    exact: true,
    path: '/settings',
    component: Settings,
    layout: Layout,
  },
  {
    key: 'MakeMoneyForWalker',
    exact: true,
    path: '/make-money-for-walker',
    component: MakeMoneyForWalker,
    layout: Layout,
  },
  {
    key: 'ForgotPassword',
    exact: true,
    path: '/forgot-password',
    component:forgotPassword,
    layout: Layout,
  },
  {
    key: 'UpdateForgotPassword',
    exact: true,
    path: '/update-forgot-password',
    component:updateForgotPassword,
    layout: Layout,
  },
  {
    key: 'WalkerProfile',
    exact: true,
    path: '/walker-profile',
    component: WalkerProfile,
    layout: Layout,
  },
  {
    key: 'Help',
    exact: true,
    path: '/help',
    component:Help,
    layout: Layout,
  },
  {
    key: 'ComingSoonPage',
    exact: true,
    path: '/coming-soon-page',
    component: ComingSoonPage,
    layout: Layout,
  },
  {
    key: 'walkerHelp',
    exact: true,
    path: '/walker-help',
    component:WalkerHelp,
    layout: Layout,
  },
  {
    key: 'ShowWalkerOnMap',
    exact: true,
    path: '/show-walker-on-map',
    component: ShowWalkerOnMap,
    layout: Layout,
  },
  {
    key: 'BackGroundCheckTraveler',
    exact: true,
    path: '/back-ground-check-traveler',
    component: BackGroundCheckTraveler,
    layout: Layout,
  },
  {
    key: 'BackGroundCheckWalker',
    exact: true,
    path: '/back-ground-check-walker',
    component: BackGroundCheckWalker,
    layout: Layout,
  },
  {
    key: 'BeginBackGroundCheckWalker',
    exact: true,
    path: '/begin-background-check-walker',
    component:BeginBackgroundCheckWalker,
    layout: Layout,
  },
  {
    key: 'BeginBackGroundCheckTraveler',
    exact: true,
    path: '/begin-background-check-traveler',
    component:BeginBackgroundCheckTraveler,
    layout: Layout,
  },
  {
    key: 'MakeMoneyForTraveler',
    exact: true,
    path: '/make-money-traveler',
    component:MakeMoneyForTraveler,
    layout: Layout,
  },
  {
    key: 'WalkerTermsTraveler',
    exact: true,
    path: '/Walker-terms-traveler',
    component: WalkerTermsTraveler,
    layout: Layout,
  },


  ];


export const privateRoutes = [
  {
    key: 'selectDestinations',
    exact: true,
    path: '/select-destinations',
    component: SelectDestinations,
    layout: Layout,
  },
  {
    key: 'scheduleTrip',
    exact: true,
    path: '/schedule-trip',
    component: ScheduleTrip,
    layout: Layout,
  },
  {
    key: 'paymentMethods',
    exact: true,
    path: '/payment-methods',
    component: PaymentMethods,
    layout: Layout

  },
  {
    key: 'addCard',
    exact: true,
    path: '/add-card',
    component: AddCard,
    layout: Layout
  },
  {
    key: 'updateCard',
    exact: true,
    path: '/update-card',
    component: UpdateCard,
    layout: Layout

  },
  {
    key: 'payoutAccount',
    exact: true,
    path: '/payout-account',
    component: PayoutAccount,
    layout: Layout

  }

];