import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';


const Checkbox = (props) => {

  const [id] = useState(uniqueId('Checkbox'));

  return (
    <div>
      <label htmlFor={id}>
        {props.label}
      </label>

      <input
        type="checkbox"
        checked={props.checked}
        id={id}
        onChange={props.onChange}
      />
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};

export default Checkbox;