import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Slider from "react-slick";

let publicUrl = process.env.PUBLIC_URL + '/';
let imgattr = 'logo';

const NewDestinations = ( props ) => {
  const { destinationsData } = props;

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };

  const sliderMap = () => {
    return destinationsData.length > 0 && destinationsData.map((d, index) => {
      if (index <= 3) {
        return (
          <div key={index} className="col-lg-3 col-sm-6">
            <div className="single-destinations-list style-two wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
              <div className="thumb">
                <img
                  className="new-destination-images"
                  src={`${d.image ? d.image : publicUrl + "assets/images/destination-list/1.png"}`}
                  alt="list"
                />
              </div>
              <div className="details">
                <p className="location"><img src={publicUrl + "assets/images/icons/1.png"} alt="map" />{d.location || 'N/A'}</p>
                <h4 className="title">{d.name || 'N/A'}</h4>
                <Link className="btn btn-yellow" to="/"><img src={publicUrl + "assets/images/huvr-btn.png"} alt={imgattr} className="huvr-btn" /></Link>
              </div>
            </div>
          </div>
        )
      }
    })
  }

  return (
    <>
      <div className="holiday-plan-area tp-holiday-plan-area mg-top-96 holiday-plan-area-row" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/bg/8.png)' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              <div className="section-title text-center">
                <h2 className="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">New Destinations</h2>
                <p className="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">Here are the Newest Destination <span className="text-block">Huvr has to offer.</span></p>
              </div>
            </div>
          </div>
          <div className="row">

          <Slider {...sliderSettings}>
            {sliderMap()}
          </Slider>

          
          </div>
        </div>
      </div>
    </>
  )
}

NewDestinations.propTypes = {
  destinationsData: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance( NewDestinations );