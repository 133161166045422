
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
import * as commonFunctions from '../../utilities/commonFunctions';
import UserUtils from '../../utilities/UserUtils';
import Banner from './banner';
import Search from './search';
import IntroSection from './intro-section';
import BetaDestination from './beta-destination';
import VideoSection from './video-section';
import NewDestinations from './new-destinatioins';
import Review from './review-section';
import Blog from './blog-section';
import Add from './add-section';
import Subscribe from './subscribe';

const DESTINATIONS_DATA = loader('../../graphql/schema/destinationView/destinations.graphql');

const Home = () => {

  //const { history } = props;
  const [destinationsData, setDestinationsData] = useState([]);
  const [size] = useState(7);
  const [page] = useState(1);

  const { data, loading, error } = useQuery(DESTINATIONS_DATA, {
    variables: { "first": size, "page": page }
  });

  useEffect(() => {
    const token = UserUtils.getAccessToken();
    if (token !== null) {
      //history.push('/select-destinations');
      return;
    }

    if (data && data.destination.data.length > 0) {
      setDestinationsData(data.destination.data);
    }
    if (error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      console.log(errorMsg)
    }
  }, [data, loading, error])

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <Banner />

      {/* serah section */}
      <Search />

      {/* intro section  */}
      <IntroSection />

      {/* Beta Destinations, offer section  */}
      {destinationsData.length > 0 &&
        <BetaDestination destinationsData={destinationsData} />
      }
      {/* video section */}
      <VideoSection />

      {/*New Destinations*/}
      {destinationsData.length > 0 &&
        <NewDestinations destinationsData={destinationsData} />
      }

      {/* reivew section  */}
      <Review />

      {/* blog section  */}
      <Blog />

      {/* Add Section */}
      <Add />

      {/* subscribe */}
      <Subscribe />
    </>
  )
}

Home.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(Home);