import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery,useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

const publicUrl = process.env.PUBLIC_URL + '/';

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const CREATE_CHECKER_INVITATION = loader('../../graphql/schema/background-check/create-checkr-invitation.graphql');

const BackGroundCheckTraveler = (props) => {

  const { history } = props;

  const { data: myDetails } = useQuery(MY_DETAILS);

  const [createCheckrInvitation] = useMutation(CREATE_CHECKER_INVITATION, {
    onCompleted(res) {
      window.location.href = res.createCheckrInvitation.invitation_url;
    },   
  });

  const handlebeginbackgroundcheck = () => {
    createCheckrInvitation();
  } 

  const handleSubmitinformation = () => {
    history.push('/begin-background-check-traveler')
  }

  return (
    <>
      <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Background Check Traveler</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="travelle-body">
              <div className="profile-row">
                <div className="profile-left">
                  <figure>
                    <img src={myDetails && myDetails.me.avatar ? myDetails.me.avatar : 'assets/images/inner-page/profile.jpg'} alt="profile" />
                  </figure>
                  <div className="profile-title">
                    <h4>Welcome back,</h4>
                    <p>
                      {myDetails && myDetails.me.first_name}
                      {' '}
                      {myDetails && myDetails.me.last_name}
                    </p>
                  </div>
                </div>
                <div className="profile-right">
                  <figure>
                    <img src={publicUrl + "assets/images/favicon.png"} alt="" />
                  </figure>
                  <span>Traveler</span>
                </div>
              </div>
              <div className="edit-form-row">
                <span><h3 className="background-check-title">Please select the option below for your country of residence.</h3></span>
                <span><h4>I am from the USA</h4></span>
                <span>This in ONLY for US Citizens</span>
                <div className="form-btn form-btn-small">
                  <Button onClick={handlebeginbackgroundcheck}>Begin Background Check</Button>
                </div>
                <span><h4>I am NOT from the USA</h4></span>
                <div className="form-btn form-btn-small">
                  <Button onClick={handleSubmitinformation}>Submit Information</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

BackGroundCheckTraveler.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(BackGroundCheckTraveler);
