import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
const publicUrl = process.env.PUBLIC_URL + '/';
const ADD_CARD = loader('../../graphql/schema/payments/add-card.graphql');

const CARD_ELEMENT_OPTIONS = {
  style: {
    border: "1px solid black",
    base: {
      border: "1px solid black",
      color: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};


const CheckoutForm = (props) => {

  const { history } = props;

  const apolloClient = useApolloClient();
  const [countryCode, setCountryCode] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [hasError, setHasError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000)
  }, [])

  const postResponseData = (res) => {
    apolloClient.writeData({ data: { isLoggedIn: true } });
    let error = {};
    if (res.status === 'CARD_ADDED') {
      setSuccessMessage(res.message);
      setTimeout(() => {
        history.push('/payment-methods');
      }, 2000);
    } else {
      error.cardError = res.message;
      setHasError(error);
    }
  };

  const [addCardSubmit, { loading: loading }] = useMutation(ADD_CARD, {
    onCompleted({
      addCard: {
        message: message,
        status: status,
      },
    }) {
      postResponseData({ status, message });
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      let error = {};
      error.cardError = formatedErrors;
      setHasError(error);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
    const data = {};
    data.address_zip = zipCode;
    data.address_country = countryCode;
    const result = await stripe.createToken(card, data);
    if (result.error) {
      console.log(result.error.message, 'if');
      errors.cardError = result.error.message;
      setHasError(errors);
    } else if (countryCode === '') {
      errors.cardError = 'Country is Required.';
      setHasError(errors);
    } else if (zipCode === '') {
      errors.cardError = 'Zip Code is Requied.';
      setHasError(errors);
    } else if (zipCode.length > 6) {
      errors.cardError = 'Enter valid zipcode';
      setHasError(errors);
    } else {
      setHasError({});
      addCardSubmit({
        variables: {
          data: {
            token: result.token.id
          },
        },
      });
      // CLEAR CARD ELEMENT FIELDS
      elements.getElement(CardNumberElement).clear();
      elements.getElement(CardExpiryElement).clear();
      elements.getElement(CardCvcElement).clear();
      setCountryCode('');
      setZipCode('');
    }
  }

  const handleZipCode = (e) => {
    setHasError({});
    setZipCode(e.target.value)
  }

  return (
    <>
     <div className="travelle-body">
        <div className="edit-form-row">
          <form onSubmit={handleSubmit}>
        {(loading || loader) && <PageLoader loading={(loading || loader)} />}
      

          {successMessage !== "" &&
            <div className="success-msg">
              {<p className="success-msg">{`${successMessage}.`}</p>}
            </div>
          }

          {hasError.cardError && <div className="is-invalid">{hasError.cardError}</div>}

          <div className="form-in">
            <label>Card Number</label>
            <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
            <figure className="form-card">
              <img src={publicUrl + "assets/images/inner-page/master.png"} alt="" />
            </figure>
          </div>
          <div className="formin-row">
            <div className="formin-half">
              <div className="form-in">
                <label>Exp.date</label>
                <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                <figure className="form-card">
                  <img src={publicUrl + "assets/images/inner-page/master.png"} alt="" />
                </figure>
              </div>
            </div>
            <div className="formin-half">
              <div className="form-in">
                <label>CVC</label>
                <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                <figure className="form-card">
                  <img src={publicUrl + "assets/images/inner-page/master.png"} alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div className="form-in">
            <label>Country</label>
            <ReactFlagsSelect
              searchPlaceholder="Search countries"
              searchable
              selected={countryCode}
              onSelect={code => setCountryCode(code)}
            />
          </div>
          <div className="form-in">
            <label>Zip Code</label>
            <input
              name="zipCode"
              placeholder="00000"
              type="text"
              value={zipCode}
              onChange={handleZipCode}
            />
            <figure className="form-card">
              <img src={publicUrl + "assets/images/inner-page/master.png"} alt="" />
            </figure>
          </div>
          <div className="form-btn btn-height">
            <button className="btn btn-yellow">Enter Card Information</button>
          </div>
     
      </form>
        </div>
      </div>
    </>
  )
}


CheckoutForm.propTypes = {
  history: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(CheckoutForm);