import React, { useState } from 'react';
import { withRouter, Link} from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import PropTypes from "prop-types";
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import $ from 'jquery';
import UserUtils from '../../../utilities/UserUtils';
import PageLoader from '../../layout/ui/page-loader';

const FORGOT_PASSWORD = loader('./../../../graphql/schema/auth/forgot-password.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';

const ForgotPassword = (props) => {
  const { history } = props;
  const apolloClient = useApolloClient();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumerError, setPhoneNumberError] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState({});

  const handlePhoneNumber = (number) => {
    if ((number !== undefined)) {
      setHasError({});
      $('#outlined-phone-number-input').prop('disabled', false);
      if (isValidPhoneNumber(number)) {
        setPhoneNumber(number);
        setPhoneNumberError("");
      } else {
        setPhoneNumberError('Please Enter valid Phone Number.');
      }
    }
  }

  const handleFocus = () => {
    if ($('#outlined-phone-number-input').val() === '') {
      $('#outlined-phone-number-input').prop('disabled', true);
      setPhoneNumberError('Please Select Country Calling Code.');
    }
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (phoneNumber === "") {
      setPhoneNumberError('Please enter Phone Number.');
      formIsValid = false;
    }
    setHasError(errors);
    return formIsValid;
  }

  const postforgotPassword = (response) => {
    let error = {};
    apolloClient.writeData({ data: { isforgotPassword: true } });
    console.log(response)
    if (response.status === "SMS_SENT") {
     UserUtils.setPhoneNumber(phoneNumber);
      setSuccessMessage(response.message);
      setTimeout(() => {
        history.push('/update-forgot-password');
      }, 1500)
    } else {
      error['otpError'] = response.message;
      setHasError(error);
    } 
  };

  const [submitforgotPassword, {loading: forgotPasswordLoading}] = useMutation(FORGOT_PASSWORD, {
    onCompleted({
      forgotPassword :{
        status: status,
        message: message
    },
    }) {
      postforgotPassword({ status, message});
    },
   onError(errors) {
     const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      let error = {};
      error['failed'] = formatedErrors;
      setHasError(error);
    },
  });
 const SubmitForgotPassword= (e) => {
    e.preventDefault();
    if (validateForm()) {
      submitforgotPassword({
        variables: {
          "data": {
            "phone": phoneNumber
          },

        },
      });
     }
  };

  
return(
    <div className="signup-container-row">
      {forgotPasswordLoading && <PageLoader loading={forgotPasswordLoading} />}
        <div className="signup-row">
          <p className="modal-close" ><Link to="/"><i className="la la-times" /></Link></p>
          <div className="signup-left">
            <div className="signup-cnt">
              <h4 component="h4">Forgot Password</h4>
              <p>Huvr is an app connecting virtual Travelers to human Huvr approved Walkers across the globe. They provide individual or group experiences unlike anything on the market today.</p>
              <Link to="/" className="btn"><i className="fa fa-external-link"></i> Learn More</Link>
            </div>
          </div>
          <div className="signup-right">
            <div className="signup-form">
              <div className="form-title">
                <figure>
                  <img src={publicUrl + "assets/images/favicon.png"} alt="" />
                </figure>
                <h4 component="h4">Welcome Back</h4>
              </div>
              <form name="phoneVerfication" onSubmit={SubmitForgotPassword}>
                {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
                {hasError.otpError !== undefined && <p className="is-invalid">{hasError.otpError}</p>}
                <div className="form-input-box">
                <label variant="subtitle2" component="label">Phone Number</label>
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  defaultCountry=""
                  id="outlined-phone-number-input"
                  placeholder="Enter Phone Number"
                  className={`phone-input-data`}
                  type="text"
                  name="phoneNumber"
                  margin="normal"
                  variant="outlined"
                  onFocus={handleFocus}
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                />
                {phoneNumerError !== "" && <p className="is-invalid">{phoneNumerError}</p>}
              </div>
                <div className="form-btn">
                  <button>Submit</button>
                </div>  
              </form>
            </div>
          </div>
        </div>
         </div>
        );
     };
     ForgotPassword.propTypes = {
        history: PropTypes.object.isRequired,
      };
      
      const enhance = compose(
        withRouter,
      );
      
    export default enhance(ForgotPassword);
      