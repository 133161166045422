import React from 'react';
import { Link } from 'react-router-dom';

const publicUrl = process.env.PUBLIC_URL + '/';

const VideoSection = () => {

  return (
    <>
      <div id="about-huvr" className="video-area tp-video-area pd-top-110 video-area-row" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/bg/7.png)' }}>
        <div className="container viaje-go-top">
          <div className="row">
            <div className="col-xl-5 col-lg-6 align-self-center wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
              <div className="section-title mb-lg-0 mb-4 text-center text-lg-left">
                <h2 className="title">What Do You Know <br /> About US</h2>
                <p>HUVR is a global virtual marketplace, that connects Human Walkers to Virtual Travelers who want to explore the world in real-time.</p>
                <p>With thousands of destinations that you can visit from your smartphone, whether you  are using the app as an inspired traveler, an investor or someone who loves to shop, educate or simply experience an array of culture and happenings in another country or city - Huvr can take you there.</p>
                <Link className="btn btn-yellow" to="/"><span>Read More<i className="la la-arrow-right" /></span></Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 offset-xl-1 wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
              <div className="video-popup-wrap">
                <div className="thumb">
                  <img src={publicUrl + "assets/images/video.png"} alt="video" />
                </div>
                <div className="video-popup-btn">
                  <a href="https://vimeo.com/525230363/89efd44e6b" target="_blank" rel="noreferrer" className="video-play-btn mfp-iframe"><i className="fa fa-play" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoSection;
