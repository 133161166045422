import React, { useEffect, useState} from 'react';
import { Typography } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
import * as commonFunctions from '../../utilities/commonFunctions';
import Rating from 'react-rating';
import  './style.css'

const RATING_DETAILS = loader('../../graphql/schema/ratings/ratings-details.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';

const Ratings = () => {

    const [ratingData, setRatingData] = useState('');
    const [haserror, setHaserror] = useState('');
    const { data, loading, error } = useQuery(RATING_DETAILS);

    useEffect(() => {
        if (data && data.getWalkerRating.length > 0) {
            setRatingData(data.getWalkerRating);
        }
        if (error) {
             let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
             setHaserror(errorMsg)
         }
     }, [data, loading, error])    

    return (
        <>
            {loading && <PageLoader loading={loading} />}
            <div className="traveller-page rating_group">
                <div className="traveller-row">
                    <div className="traveller-head">
                        <div className="container">
                            <div className="traveller-head-row">
                                <div className="traveller-head-left">
                                    <h2>Rating Details</h2>
                                </div>
                                <div className="traveller-head-right">
                                    <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                    {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
                        <div className="destination-list-body">
                            <div  className="text-center rating-row">
                                <Typography>Number Of Walks</Typography>
                                <div>{ratingData.length || '0'}</div>
                            </div>
                            {!ratingData && <p>You have not yet received a rating come back to this page after completing more walks</p>}
                            {ratingData.length > 0 && ratingData.map((d, index) => {
                                return (
                                    <div key={index} className="m-2">
                                        <div className="border">
                                            <div className="d-flex ">
                                                <figure style={{ width: "100px", height: "200px" }}>
                                                    <img src={d.traveller === null ? publicUrl + "assets/images/inner-page/profile.jpg" : d.traveller.avatar} alt="" />
                                                </figure>
                                                <div>
                                                    <h5>{d.traveller === null ? 'N/A' : d.traveller.first_name}</h5>
                                                    <p>{d.comment || 'no comment'}</p>
                                                </div>
                                            </div>
                                            <div className="rating_icons">
                                                <Rating
                                                    emptySymbol="fa fa-star-o fa-2x"
                                                    fullSymbol="fa fa-star fa-2x "
                                                    initialRating={`${d.rating === null ? 0 : d.rating}`}
                                                    readonly
                                                />
                                                <span className="m-2">{d.rating || '0'}</span>Ratings
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ratings