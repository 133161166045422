import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import PageLoader from '../../components/layout/ui/page-loader';
import * as commonFunctions from '../../utilities/commonFunctions';
import Rating from 'react-rating';

const YOUR_TRIPS = loader('../../graphql/schema/traveller/your-trips.graphql');

const YourTrip = (props) => {

  const { history } = props;

  const [archivedTrip, setArchivedTrips] = useState('');  
  const [haserror, setHaserror] = useState('');

  const { data, loading, error } = useQuery(YOUR_TRIPS);

  useEffect(() => {
    if (data && data.archivedTrips.length > 0) {
      const LatestData = data.archivedTrips;
      setArchivedTrips(LatestData.splice(0,3));
    }
    if (error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setHaserror(errorMsg)
    }
  }, [data, loading, error])

  const handleCancelledTrips = () => {
    history.push('/traveller-cancelled-trips');
  }

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Your Trips</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="trips-tab-row">
              {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Past</a>
                  <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Upcoming</a>
                </div>
              </nav>
              <div className="tab-content tab-content-new" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  {!archivedTrip && <p className="no-data">No Data To Display</p>}
                  {archivedTrip.length > 0 && archivedTrip.map((d, index) => {
                    return (
                      <div className="panel-row" key={index}>
                        <div className="panel-head">
                          <div className="panel-head-left">
                            <p>{d.created_at || 'N/A'}</p>
                          </div>
                          <div className="panel-head-right">
                            <div className="list-rating-flex">
                              <div className="list-rating-row">
                                <div className="price-rate">
                                  <span><i className="fa fa-dollar"></i>{d.total_trip_price || 0}</span>
                                </div>
                                <div className="list-rating">
                                  <Rating
                                    emptySymbol="fa fa-star-o fa-2x"
                                    fullSymbol="fa fa-star fa-2x "
                                    initialRating={`${d.ratings === null ? 0 : d.ratings.rating}`}
                                    readonly
                                  />
                                </div>
                              </div>
                              <div className="trip-price-btn">
                                <i className="fa fa-angle-right"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        {d.video_url === null ?
                          <p>no vedio found</p>
                          :
                          <div className="panel-video">
                            <iframe title="Travelers Experiances" src={`${d.video_url === null ? '' : d.video_url}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen controls="controls"/>
                          </div>
                        }
                        <div className="subscribe-btn">
                          <button className="btn btn-yellow">Subscribe today to Save More Trips</button>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div className="subscribe-btn subscribe-btn-cancel">
                    <button className="btn btn-yellow" onClick={handleCancelledTrips}>Cancelled Trips</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

YourTrip.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(YourTrip)
