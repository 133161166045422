import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import PropTypes from "prop-types";
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import $ from 'jquery';
import UserUtils from '../../../utilities/UserUtils';
import PageLoader from '../../layout/ui/page-loader';

const VERIFY_PHONE_NUMBER = loader('./../../../graphql/schema/auth/validate-phone-number.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';

const Login = (props) => {
  const { history } = props;
  const apolloClient = useApolloClient();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumerError, setPhoneNumberError] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [mobileNumber, setMobileNumber] = useState(false);
  const [hasError, setHasError] = useState({});

  const handlePhoneNumber = (number) => {
    if ((number !== undefined)) {
      setHasError({});
      setMobileNumber(false);
      $('#outlined-phone-number-input').prop('disabled', false);
      if (isValidPhoneNumber(number)) {
        setPhoneNumber(number);
        setPhoneNumberError("");
      } else {
        setPhoneNumberError('Please Enter valid Phone Number.');
      }
    }
  }

  const handleFocus = () => {
    if ($('#outlined-phone-number-input').val() === '') {
      $('#outlined-phone-number-input').prop('disabled', true);
      setPhoneNumberError('Please Select Country Calling Code.');
    }
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (phoneNumber === "") {
      setPhoneNumberError('Please enter Phone Number.');
      formIsValid = false;
    }
    setHasError(errors);
    return formIsValid;
  }


  const postLogin = (resUser) => {
    apolloClient.writeData({ data: { isLoggedIn: false } });
    let error = {};
    UserUtils.setPhoneNumber(phoneNumber);
    if (resUser.status === "NEW_USER") {
      setSuccessMessage(resUser.message);
      history.push('verify-otp');
    } else if (resUser.status === "OTP_FAIL") {
      error['otpError'] = resUser.message;
      setHasError(error);
    } else if (resUser.status === "USER_EXIST") {
      // REDIRECT TO LOGIN OTP PAGE IF USER EXIST
      setSuccessMessage(resUser.message);
      history.push('/verify-login');
    }
  };

  const { loading } = useQuery(VERIFY_PHONE_NUMBER, {
    variables: {
      phone: phoneNumber,
    },
    fetchPolicy: 'network-only',
    skip: mobileNumber === false,
    onCompleted({
      verifyPhoneNumber: {
        status: status,
        message: message
      },
    }) {
      postLogin({ status, message });
    }
  });

  const submitPhoneNumber = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setMobileNumber(true);
    }
  };

  return (
    <div className="signup-container-row">
      {loading && <PageLoader loading={loading} />}
      <div className="signup-row">
        <p className="modal-close" ><Link to="/"><i className="la la-times" /></Link></p>
        <div className="signup-left">
          <div className="signup-cnt">
            <h4 component="h4">Login</h4>
            <p>Huvr is an app connecting virtual Travelers to human Huvr approved Walkers across the globe. They provide individual or group experiences unlike anything on the market today.</p>
            <Link to="/" className="btn"><i className="fa fa-external-link"></i> Learn More</Link>
          </div>
        </div>
        <div className="signup-right">
          <div className="signup-form">
            <div className="form-title">
              <figure>
                <img src={publicUrl + "assets/images/favicon.png"} alt="" />
              </figure>
              <h4 component="h4">Welcome Back</h4>
            </div>
            <form name="phoneVerfication" onSubmit={submitPhoneNumber}>
              {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
              {hasError.otpError !== undefined && <p className="is-invalid">{hasError.otpError}</p>}
              <div className="form-input-box">
                <label variant="subtitle2" component="label">Phone Number</label>
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  defaultCountry=""
                  id="outlined-phone-number-input"
                  placeholder="Enter Phone Number"
                  className={`phone-input-data`}
                  type="text"
                  name="phoneNumber"
                  margin="normal"
                  variant="outlined"
                  onFocus={handleFocus}
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                />
                {phoneNumerError !== "" && <p className="is-invalid">{phoneNumerError}</p>}
              </div>
              <div className="signup-two">
                <div className="signup-checkbox">
                  <input type="checkbox" />
                  <label>Remember Me</label>
                </div>
                <div className="signup-forgot">
                <Link to="/forgot-password"> <span>Forgot Password?</span></Link>
                </div>
              </div>
              <div className="form-btn">
                <input type="submit" color="inherit" value="Log In" />
              </div>
            </form>
            <div className="form-signup-cnt">
              <p>Dont&sbquo;y have an account? <Link to="/register">Register</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  // withApollo,
);

export default enhance(Login);
