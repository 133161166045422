import React from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './checkout-form';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AddCard = () => {

  return (
    <>
      <div className="traveller-page add-card-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Add Card</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <CheckoutForm stripe={stripe} elements={elements} />
                )}
              </ElementsConsumer>
            </Elements>
          </div>
        </div>
      </div>
    </>
  )
}

const enhance = compose(
  withRouter,
);

export default enhance(AddCard);