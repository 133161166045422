import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from "prop-types";
import OtpInput from 'react-otp-input';
import { loader } from 'graphql.macro';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import UserUtils from '../../../utilities/UserUtils';
import PageLoader from '../../layout/ui/page-loader';

const LOGIN = loader('./../../../graphql/schema/auth/login.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';

const VerifyLogin = (props) => {
  const { history } = props;
  const apolloClient = useApolloClient();
  
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneOtp, setPhoneOtp] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState({});
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const phone = UserUtils.getPhoneNumber();
    if (phone !== null) {
      setPhoneNumber(phone);
    } else {
      history.push('/login');
    }
    return () => {
      UserUtils.removePhoneNumber();
    };
  }, [])

  //HANDLE PHONE INPUT
  const handlePhoneNumber = (e) => {
    setHasError({});
    setPhoneNumber(e.target.value)
  }
  // HANDLE OTP
  const handleChangeOtp = (otp) => {
    setPhoneOtp(otp);
    setPassword('');
  }
  // HANDLE PASSWORD
  const handlePassword = (e) => {
    setHasError({});
    setPassword(e.target.value)
  }

  // VALIDATE OTP
  const validatePhoneOtp = () => {
    let formIsValid = true;
    let errors = {};
    if (phoneOtp === '' && password === '') {
      formIsValid = false;
      errors["phoneOtp"] = "Please enter OTP.";
    } else if (phoneOtp.length < 5 && password === '') {
      formIsValid = false;
      errors["phoneOtp"] = "Please enter valid OTP.";
    }
    setHasError(errors);
    return formIsValid;
  }

  const postLogin = (resUser) => {
    apolloClient.writeData({ data: { isLoggedIn: true } });
    let error = {};
    if (resUser.status === "OTP_NOT_VERIFIED") {
      error['otpError'] = resUser.message;
      setHasError(error);
    } else if (resUser.status === "NOT_VERIFIED") {
      error['otpError'] = resUser.message;
      setHasError(error);
    } else {
      setSuccessMessage(resUser.message)
      UserUtils.setAccessToken(resUser.accessToken);
      localStorage.setItem( 'access_token', resUser.accessToken );
      apolloClient.writeData( { data: { isLoggedIn: true } } );
      UserUtils.setUserID(resUser.user.id);
      history.push('/select-destinations');
    }
  };

  const [submitSignUp, { loading: loading }] = useMutation(LOGIN, {
    onCompleted({
      login: {
        access_token: accessToken,
        status: status,
        message: message,
        user: user,
      },
    }) {
      postLogin({ accessToken, status, message, user });
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  const submitLogin = (e) => {
    e.preventDefault();
    if (validatePhoneOtp()) {
      submitSignUp({
        variables: {
          data: {
            "otp": phoneOtp ? phoneOtp : '',
            "username": phoneNumber,
            "password": password,
            "phone_country_key": "",
            "phone_country_code": "",
            "time_zone": "",
            "device_token": ""
          },
        },
      });
    }
  };

  return (
    <div className="signup-container-row">
      {loading && <PageLoader loading={loading} />}
      <div className="signup-row">
        <p className="modal-close" ><Link to="/"><i className="la la-times" /></Link></p>
        <div className="signup-left">
          <div className="signup-cnt">
            <h4 component="h4">Login</h4>
            <p>Huvr is an app connecting virtual Travelers to human Huvr approved Walkers across the globe. They provide individual or group experiences unlike anything on the market today.</p>
            <Link to="/" className="btn"><i className="fa fa-external-link"></i> Learn More</Link>
          </div>
        </div>
        <div className="signup-right">
          <div className="signup-form">
            <div className="form-title">
              <figure>
                <img src={publicUrl + "assets/images/favicon.png"} alt="" />
              </figure>
              <h4 component="h4">Welcome to Huvr</h4>
            </div>
            <form onSubmit={submitLogin}>
              {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
              {hasError.otpError !== undefined && <p className="is-invalid">{hasError.otpError}</p>}
              <div className="form-input-box-1">
                <label variant="subtitle2" component="label">Enter OTP Here</label>
                <div className="otp-input">
                  <OtpInput
                    variant="outlined"
                    name="phoneOtp"
                    margin="normal"
                    shouldAutoFocus={true}
                    className={``}
                    onChange={handleChangeOtp}
                    numInputs={5}
                    value={phoneOtp}
                    separator={<span>-</span>
                    }
                  />
                </div>
                {hasError.phoneOtp !== undefined && <div className='is-invalid'>{hasError.phoneOtp}</div>}
              </div>

              <div className="or-login text-center">OR</div>

              <div className="form-input-box">
                <label variant="subtitle2" component="label">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  className={hasError.phoneNumber !== undefined ? 'is-invalid' : ''}
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                  disabled
                />
              </div>
              <div className="form-input-box">
                <label variant="subtitle2" component="label">Password</label>
                <input
                  type="password"
                  name="password"
                  className={`${hasError.password !== undefined ? 'is-invalid' : ''}`}
                  value={password}
                  onChange={handlePassword}
                />
                {hasError.password !== undefined && <p className="is-invalid">{hasError.password}</p>}
              </div>
              <div className="form-btn">
                <input type="submit" color="inherit" value="Verify" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

VerifyLogin.propTypes = {
  history: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
};

const enhance = compose(
  withRouter,
);

export default enhance( VerifyLogin );
