import React from 'react';
import { Link } from 'react-router-dom';

const Search = () => {

  return (
    <>
      <div className="search-area tp-main-search-area viaje-go-top">
        <div className="container">
          <div className="tp-main-search">
            <div className="row">
              <div className="col-lg-10 col-md-8">
                <div className="tp-search-single-wrap">
                  <input className="w-100" type="text" placeholder="Search our Available Beta Destinations" />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 order-12">
                <Link className="btn btn-yellow" to="/"><i className="ti-search" /> Search</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Search;
