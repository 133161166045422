const ACCESS_TOKEN = "huvr::access_token";
const USER_ID = "huvr::user_id";
const PHONE_NUMBER = "huvr::phone_number";
const CARD_ID = "huvr::card_id";
const DESTINATIONS_DATA = "huvr:destination_data";

const userUtils = {
  /* Autherization */
  setAccessToken(access_token) {
    localStorage.setItem(ACCESS_TOKEN, access_token);
  },
  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  },
  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN);
  },
  //SET USER ID
  setUserID(user_id) {
    localStorage.setItem(USER_ID, user_id);
  },
  getUserID() {
    return localStorage.getItem(USER_ID);
  },
  removeUserID() {
    localStorage.removeItem(USER_ID);
  },
  // PHONE NUMBER
  setPhoneNumber(phone_number) {
    localStorage.setItem(PHONE_NUMBER, phone_number);
  },
  getPhoneNumber() {
    return localStorage.getItem(PHONE_NUMBER);
  },
  removePhoneNumber() {
    localStorage.removeItem(PHONE_NUMBER);
  },
  //  CARD ID TO UPDATE
  setCardId(card_id) {
    localStorage.setItem(CARD_ID, card_id);
  },
  getCardId() {
    return localStorage.getItem(CARD_ID);
  },
  removeCardId() {
    localStorage.removeItem(CARD_ID);
  },
  //DESTINATION DATA
  setDestinationData(destination_data) {
    localStorage.setItem(DESTINATIONS_DATA, destination_data);
  },
  getDestinationData() {
    return localStorage.getItem(DESTINATIONS_DATA);
  },
  removeDestinationData() {
    localStorage.removeItem(DESTINATIONS_DATA);
  },


  /* Logout */
  logout() {
    //localStorage.clear();
    /* Autherization */
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(PHONE_NUMBER);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(CARD_ID);
    localStorage.removeItem(DESTINATIONS_DATA);

  }
}
export default userUtils;
