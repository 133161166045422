import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CallMadeOutlinedIcon from '@material-ui/icons/CallMadeOutlined';
import userUtils from '../../utilities/UserUtils';
import PageLoader from '../../components/layout/ui/page-loader';

const SAVE_USER_PREFERENCE= loader('../../graphql/schema/settings/save-user-preferences.graphql');
const GET_USER_PREFERENCE= loader('../../graphql/schema/settings/get-user-preferences.graphql');

const Settings = ( props ) => {
  const { history } = props;
  const apolloClient = useApolloClient();

  
  const [frequency, setFrequency] = useState(0);
  const [languages, setLanguages] = useState([]);
  const [interest, setInterest] = useState([]);
  const [newDestination, setNewDestination] = useState( false );
  const [liveEvent, setLiveEvent] = useState( false );
  const [liveNews, setLiveNews] = useState( false );
  const [pauseNotification, setPauseNotification] = useState( false );
  const [showTravellerSplash, setShowTravellerSplash] = useState( false );
  const [showWalkerSplash, setShowWalkerSplash] = useState( false );

  const { data: getUserPreference, loading } = useQuery(GET_USER_PREFERENCE);

  const [updateSaveSettings] = useMutation( SAVE_USER_PREFERENCE, {
    refetchQueries: [
      {
        query: GET_USER_PREFERENCE,
        fetchPolicy: 'network-only',
      },
    ],
  } );

  useEffect(() => {
    if ( getUserPreference ) {
      setFrequency( getUserPreference && getUserPreference.getUserPreference.frequency );
      setLanguages( getUserPreference && getUserPreference.getUserPreference.languages );
      setInterest( getUserPreference && getUserPreference.getUserPreference.interests );
      setNewDestination( getUserPreference && getUserPreference.getUserPreference.new_destination );
      setLiveEvent( getUserPreference && getUserPreference.getUserPreference.live_event );
      setLiveNews( getUserPreference && getUserPreference.getUserPreference.live_news );
      setPauseNotification( getUserPreference && getUserPreference.getUserPreference.pause_notification );
      setShowTravellerSplash( getUserPreference && getUserPreference.getUserPreference.show_traveller_splash );
      setShowWalkerSplash( getUserPreference && getUserPreference.getUserPreference.show_walker_splash );
    }
  }, [loading])

  const handleChangeFrequency = (e) => {
    setFrequency(e.target.value);
    updateSaveSettings( {
      variables: {
        data: {
          frequency: e.target.value
        },
      },
    } );
  }

  const handleChangeLanguages = (e) => {
    setLanguages(e.target.value);
    updateSaveSettings( {
      variables: {
        data: {
          languages: e.target.value
        },
      },
    } );
  }

  const handleChangeInterest = (e) => {
    setInterest(e.target.value);
    updateSaveSettings( {
      variables: {
        data: {
          interests: e.target.value
        },
      },
    } );
  }

  const handleChangeNewDestination = (e) => {
    setNewDestination(e.target.checked);
    updateSaveSettings( {
      variables: {
        data: {
          new_destination: e.target.checked
        },
      },
    } );
  }

  const handleChangeLiveEvent = (e) => {
    setLiveEvent(e.target.checked);
    updateSaveSettings( {
      variables: {
        data: {
          live_event: e.target.checked
        },
      },
    } );
  }

  const handleChangeLiveNews = (e) => {
    setLiveNews(e.target.checked);
    updateSaveSettings( {
      variables: {
        data: {
          live_news: e.target.checked
        },
      },
    } );
  }

  const handleChangePauseNotification = (e) => {
    setPauseNotification(e.target.checked);
    updateSaveSettings( {
      variables: {
        data: {
          pause_notification: e.target.checked
        },
      },
    } );
  }

  const handleChangeShowTravellerSplash = (e) => {
    setShowTravellerSplash(e.target.checked);
    updateSaveSettings( {
      variables: {
        data: {
          show_traveller_splash: e.target.checked
        },
      },
    } );
  }

  const handleChangeShowWalkerSplash = (e) => {
    setShowWalkerSplash(e.target.checked);
    updateSaveSettings( {
      variables: {
        data: {
          show_walker_splash: e.target.checked
        },
      },
    } );
  }

  const handleSignout = () => {
		userUtils.removeAccessToken();
		apolloClient.resetStore();
		history.push('/');
  };

  const handleTwitter = () => {
    window.location= "https://twitter.com/HuvrApp"
  }

  const handleInstagram = () => {
    window.location= "https://www.instagram.com/huvrapp/"
  }
  
  const handleContactus =()=>{
    window.location= "https://marketing.huvr.com/contact-us/"
  }

  const handleFaq =()=>{
    window.location= "https://marketing.huvr.com/faq/"
  }

 const handleWhatsnew =()=>{
   window.location= "https://marketing.huvr.com/whats-new/"
 } 

 const handleCommunityguidelines =()=>{
   window.location= "https://marketing.huvr.com/community-guidelines/"
 }

const handleTermsofservices =()=>{
  window.location= "https://marketing.huvr.com/terms-of-use/"
}

 const handlePrivacypolicy =()=>{
   window.location= "https://marketing.huvr.com/privacy-policy/"
 }

  return (
    <div className="traveller-page">
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-row">
        <div className="traveller-head">
          <div className="container">
            <div className="traveller-head-row">
              <div className="traveller-head-left">
                <h2>Settings</h2>
              </div>
              <div className="traveller-head-right">
                <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="travelle-body">
            <div className="edit-form-row">
            
           <div className="setting-title">
                <h4>Notifications</h4>
              </div>
              <div className="setting-box">
                <div className="setting-div">
                  <div className="setting-left">
                    Frequency    
                  </div>
                  <div className="setting-right">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={frequency}
                      onChange={handleChangeFrequency}
                    >
                      <option value='0'>Very Frequent</option>
                      <option value='1'>Frequent</option>
                      <option value='2'>Normal</option>
                      <option value='3'>Infrequent</option>
                      <option value='4'>Very Infrequent</option>
                    </Select>
                  </div>
                </div>
                <div className="setting-switch">
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position">
                      <FormControlLabel
                        control={<Switch color="primary" checked={newDestination} onChange={handleChangeNewDestination} name="newDestination" />}
                        label="Include New Destinations"
                        labelPlacement="Start"
                      />
                      <FormControlLabel
                        control={<Switch color="primary" checked={liveEvent} onChange={handleChangeLiveEvent} name="liveEvent" />}
                        label="Include Live Events"
                        labelPlacement="Start"
                      />
                      <FormControlLabel
                        control={<Switch color="primary" checked={liveNews} onChange={handleChangeLiveNews} name="liveNews" />}
                        label="Include Live News"
                        labelPlacement="Start"
                      />
                      <FormControlLabel
                        control={<Switch color="primary" checked={pauseNotification} onChange={handleChangePauseNotification} name="pauseNotification" />}
                        label="Pause Notifications"
                        labelPlacement="Start"

                      />
                      <FormControlLabel
                      control={<Switch color="primary" checked={showTravellerSplash} onChange={handleChangeShowTravellerSplash} name="showTravellerSplash" />}
                        label="Traveler Splash Screens"
                        labelPlacement="Start"

                      />
                      <FormControlLabel
                        control={<Switch color="primary" checked={showWalkerSplash} onChange={handleChangeShowWalkerSplash} name="showWalkerSplash" />}
                        label="Walker Splash Screens"
                        labelPlacement="Start"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
              <div className="setting-box">
                <div className="setting-div">
                  <div className="setting-left">
                    Interests
                  </div>
                  <div className="setting-right">
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={interest}
                    onChange={handleChangeInterest}
                    >
                    <option value='0'>Landmarks</option>
                    <option value='1'>Shoppings</option>
                    <option value='2'>Museums</option>
                    <option value='3'>Beaches</option>
                    <option value='4'>Architecture</option>
                    <option value='5'>History</option>
                    <option value='6'>Dating</option>
                    <option value='7'>Real Estate</option>
                    <option value='8'>News</option>
                    <option value='9'>Events</option>
                    <option value='10'>Sports</option>
                    <option value='11'>Charity</option>
                    <option value='12'>Education</option>
                    <option value='13'>Antiques</option>
                  </Select>
                </div>
                </div>
              </div>
              <div className="setting-box">
                <div className="setting-div">
                  <div className="setting-left">
                    Languages I Speak
                  </div>
                  <div className="setting-right">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={languages}
                      onChange={handleChangeLanguages}
                    >
                      <option value='0'>English</option>
                      <option value='1'>Spanish</option>
                      <option value='2'>Chinese</option>
                      <option value='3'>Hindi</option>
                      <option value='4'>Japanese</option>
                      <option value='5'>Arabic</option>
                      <option value='6'>Bengali</option>
                      <option value='7'>Portugese</option>
                      <option value='8'>Russian</option>
                      <option value='9'>Lahnda</option>
                      <option value='10'>Italian</option>
                      <option value='11'>Greek</option>
                      <option value='12'>French</option>
                      <option value='13'>German</option>
                    </Select>
                  </div>
                </div>
              </div>

              <div className="setting-box setting-box2">
                <div className="setting-div">
                  <div className="setting-left">
                    What&apos;s New  
                  </div>
                  <div className="setting-right">
                    <IconButton>
                      <CallMadeOutlinedIcon onClick={handleWhatsnew} />
                    </IconButton>
                  </div>
                </div>
              

              <div className="setting-div">
                <div className="setting-left">
                  FAQ  
                </div>
                <div className="setting-right">
                  <IconButton>
                    <CallMadeOutlinedIcon  onClick={handleFaq}/>
                  </IconButton>
                </div>
             </div>

             <div className="setting-div">
              <div className="setting-left">
                Contact Us 
              </div>
              <div className="setting-right">
                <IconButton>
                  <CallMadeOutlinedIcon onClick={handleContactus} />
                </IconButton>
              </div>
            </div>

            <div className="setting-div">
              <div className="setting-left">
                Community Guidlines
              </div>
              <div className="setting-right">
                <IconButton>
                  <CallMadeOutlinedIcon onClick={handleCommunityguidelines} />
                </IconButton>
              </div>
            </div>

            <div className="setting-div">
              <div className="setting-left">
                Terms Of Services 
              </div>
              <div className="setting-right">
                <IconButton>
                  <CallMadeOutlinedIcon onClick={handleTermsofservices} />
                </IconButton>
              </div>
            </div>
           
            <div className="setting-div">
              <div className="setting-left">
                  Privacy Policy
              </div>
              <div className="setting-right">
                <IconButton>
                  <CallMadeOutlinedIcon onClick={handlePrivacypolicy} />
                </IconButton>
              </div>
            </div>
          
           <div className="setting-div">
              <div className="setting-left">
                Connect 
              </div>
              <div className="setting-right">
                <Button onClick={handleTwitter}>Twitter</Button>
              </div>
            </div>
            <div className="setting-div">
              <div className="setting-left">
                Connect 
              </div>
              <div className="setting-right">
                <Button onClick={handleInstagram}>Instagram</Button>
              </div>
            </div>
            </div>
            <div className="setting-box setting-logout">
              <Button onClick={handleSignout}>Logout</Button>
            </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Settings.propTypes = {
  location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const enhance = compose(
	withRouter,
);

export default enhance(Settings);
