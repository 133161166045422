import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Checkbox from '@material-ui/core/Checkbox';
import PageLoader from '../../components/layout/ui/page-loader';

const WALKER_TRAINING = loader('../../graphql/schema/walker/walker-training.graphql');
const WALKER_TRAINING_VIDEO = loader('../../graphql/schema/walker/walker-training-video.graphql');


const WalkerTraining = (props) => {

  const { history } = props;

  const [hasError, setHasError] = useState('');
  const [termsConditions, setTermsConditions] = useState(false);
  const [submitbtn, setSubmitBtn] = useState(false);

  const { data: trainingVideo, loading } = useQuery(WALKER_TRAINING_VIDEO);

  //console.log('trainingVideo-----', trainingVideo)

  const [updateWalkerKit] = useMutation(WALKER_TRAINING, {
     onCompleted() {
      history.push('/make-money-for-walker');
     },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  const handleTermsAndCondition = (e) => {
    setTermsConditions(e.target.checked);
  };

  const end = () => {
    setSubmitBtn(true);
  }

  const handleSubmit = () =>{
    updateWalkerKit({
      variables: {
        status: termsConditions,
      },
    });
  } 

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      {/* <div className="banner-area viaje-go-top banner-area-new">
        <div className="banner-slider">
          <div className="video-banner-area banner-bg-1">
            <iframe title="banner" src={trainingVideo && trainingVideo.walkerTrainingVideo.video_url} allow="autoplay" frameBorder="0" data-origwidth="640" data-origheight="360"></iframe>

          </div>
        </div>
      </div> */}
      {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
      <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Walker Training</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="travelle-body">
              <div className="panel-video">
                <video width="320" height="240" controls src={trainingVideo && trainingVideo.walkerTrainingVideo.video_url} onEnded={end}>
                  Your browser does not support the video tag.
                </video>
                {/*<iframe title="banner" src={trainingVideo && trainingVideo.walkerTrainingVideo.video_url} allow="autoplay" frameBorder="0" data-origwidth="640" data-origheight="360"></iframe>*/}
              </div>
              <div className="walker-training-row">
                <span className="walker-training-checkbox">
                  <Checkbox
                    name="termsandcondition"
                    color="primary"
                    checked={termsConditions}
                    onChange={handleTermsAndCondition}
                  />
                </span>
                <label>
                  I agree to follow the recommended best practices for Walker, and to provide the traveler with an excellent trip experience.
                </label>
                {submitbtn === true ?
                  <div className="schedule-btn ">
                    <button className="btn btn-yellow m-3" onClick={handleSubmit}>Submit</button>
                  </div>
                  :
                  ''
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

WalkerTraining.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(WalkerTraining);
