import React, { useEffect ,  useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import * as commonFunctions from '../../utilities/commonFunctions';
import UserUtils from '../../utilities/UserUtils';

const publicUrl = process.env.PUBLIC_URL + '/';

const TRAVELLER_UPCOMING_WALKS = loader('../../graphql/schema/traveller/traveller-upcomingWalks.graphql');

const TravellerUpcomingWalks = () => {

    const [upcomingWalkData, setUpcomingWalkData] = useState('');
    const [size] = useState(1);
    const [page, setPage] = useState(1);
    const [haserror, setHaserror] = useState('');
    const [pageCount, setPageCount] = useState(0);

    const { data, loading, error } = useQuery(TRAVELLER_UPCOMING_WALKS, {
        variables: { "first": size, "page": page }
    });

    const handlePage = (pageNo) => {
        let selected = pageNo.selected + 1;
        setPage(selected);
        scrollToTop();
    }

    useEffect(() => {
        if (data && data.getTravelerUpcomingTrips.data > 0) {
            setUpcomingWalkData(data.getTravelerUpcomingTrips.data);
            setPageCount(data.destination.paginatorInfo.lastPage)
        }
        if (error) {
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
            setHaserror(errorMsg)
        }
    }, [data, loading, error])

    const scrollToTop = () => {
        if ($("#destinationsTab").offset() !== undefined) {
            $('html, body').animate({
                scrollTop: $("#destinationsTab").offset().top
            }, 1000)
        }
    }

    const ScheduleTrip = (data) => {
        UserUtils.setDestinationData(JSON.stringify(data));
        $('body').removeClass('modal-open');
        $('.modal-backdrop').removeClass();
        history.push('/schedule-trip');
    }

    return (
        <>
            <div className="traveller-page">
                <div className="traveller-row">
                    <div className="traveller-head">
                        <div className="container">
                            <div className="traveller-head-row">
                                <div className="traveller-head-left">
                                    <h2>Traveller Upcoming Walk</h2>
                                </div>
                                <div className="traveller-head-right">
                                    <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
                    <div className="container">
                        <div className="destination-body">
                            {!upcomingWalkData && <p className="no-data">No Data To Display</p>}

                            {upcomingWalkData.length > 0 && upcomingWalkData.map((d) => {
                                return (
                                    <div className="destination-box" key={d.id}>
                                        <div className="box-left">
                                            <figure>
                                                <img alt="" src={publicUrl + "assets/images/inner-page/opera.jpg"} />
                                            </figure>
                                        </div>
                                        <div className="box-center">
                                            <h4>{d.destination || 'N/A'}</h4>
                                            <p>{d.location || 'N/A'}</p>
                                        </div>
                                        <div className="box-right">
                                            <i className="fa fa-angle-right" data-toggle="modal" data-target="#myModal"></i>
                                        </div>
                                    </div>
                                )
                            })}

                            {pageCount > 1
                                ? <div className="search-pagination">
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-double-left"></i>}
                                            nextLabel={<i className="fa fa-angle-double-right"></i>}
                                            breakLabel={<a href="#/">...</a>}
                                            pageCount={pageCount}
                                            onPageChange={handlePage}
                                            breakClassName={"break-me"}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link" />
                                    </ul>
                                </div>
                                : ''
                            }

                        </div>
                    </div>
                </div>
            </div>

            {/* popup start here */}
            {upcomingWalkData.length >0 && upcomingWalkData.map((d, index) => {
                return (
                    <div key={index} className="modal fade destination-body-popup" id={`modal-${index}`}>
                        <div>
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content payment-popup-body">
                                    <div className="popup-scroll">
                                        <button type="button" className="close" data-dismiss="modal"><i className="la la-times"></i></button>
                                        <figure className="destination-img">
                                            <img
                                                className="select-dest-img-modal"
                                                alt={d.name}
                                                src={`${d.image ? d.image : publicUrl + "assets/images/destination-list/1.png"}`}
                                            />
                                        </figure>
                                        <div className="payment-popup-cnt">
                                            <h4>{d.name}</h4>
                                            <em>{d.location}</em>
                                            <p>{d.description}</p>
                                        </div>
                                    </div>
                                    <div className="payment-popup-btn">
                                        <button className="btn btn-yellow" onClick={() => ScheduleTrip(d)}>Huvr there Now!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

TravellerUpcomingWalks.propTypes = {
    history: PropTypes.object.isRequired,
  };
  
  const enhance = compose(
    withRouter,
  );

export default enhance(TravellerUpcomingWalks);
