import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { TextField } from 'final-form-material-ui';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import { loader } from 'graphql.macro';

const CHANGE_PASSWORD = loader('./../../../graphql/schema/auth/change-password.graphql');
//const publicUrl = process.env.PUBLIC_URL + '/';

const ChangePassword = ( props ) => {
  const { history } = props;

  const [hasError, setHasError] = useState('');
  const [passCheck, setPassCheck] = useState( false );

  const [updateMyAccount] = useMutation( CHANGE_PASSWORD, {
    onCompleted() {
      history.push('/edit-account');
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  } );

  const validate = ( values ) => {
    const errors = {};

    if ( !values.old_password ) {
      errors.old_password = 'Old password is required.';
    }

    if ( !values.new_password ) {
      errors.new_password = 'New password is required.';
    }

    if ( !values.confirm_password ) {
      errors.confirm_password = 'Confirm  password is required.';
    } else if ( values.confirm_password !== values.new_password ) {
      errors.confirm_password = 'Password and confirm password should be same.';
    }

    return errors;
  };

  const onSubmitUsernamePassword = ( values ) => {
    updateMyAccount( {
      variables: {
        data: {
          old_password: values.old_password,
          new_password: values.new_password,
        },
      },
    } );
  };

  return (
    <>
      <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                    <h4>Change Password</h4>
                  </div>
                 <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
            </div>
            
          <div className="container">
            <div className="travelle-body">
              <div className="edit-form-row">
                {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
                <Form
              onSubmit={ onSubmitUsernamePassword }
              validate={ validate }
              render={ ( { handleSubmit, submitting } ) => (
                <form
                  onSubmit={ handleSubmit }
                  noValidate
                >
                  
                <React.Fragment>
                  <div className="form-in">
                    <span>Old Password</span>
                    <Field
                      id="old_password"
                      placeholder="Old password"
                      type="password"
                      name="old_password"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={ TextField }

                    />
                  </div>
                  <div className="form-in">
                    <span>New Password</span>
                    <Field
                      id="new_password"
                      placeholder="New password"
                      type="password"
                      name="new_password"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      onFocus={ () => { setPassCheck( true ); } }
                      onBlur={ () => { setPassCheck( false ); } }
                    />
                    {passCheck === true && (
                    <ul className="form-in">
                      <li>
                        Include at least 8 characters
                      </li>
                      <li>
                        Include upper and lower case characters
                      </li>
                      <li>
                        Include at least 1 number
                      </li>
                      <li>
                        Passwords match
                      </li>
                    </ul>
                    )}
                  </div>
                  <div className="form-in">
                    <span>Confirm  Password</span>
                    <Field
                      id="confirm_password"
                      placeholder="Confirm  password"
                      type="password"
                      name="confirm_password"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      onFocus={ () => { setPassCheck( true ); } }
                      onBlur={ () => { setPassCheck( false ); } }
                    />
                  </div>

                </React.Fragment>
                
                  <div className="form-btn">
                     <Button type="submit" disabled={ submitting }>Change password</Button>
                  </div>
                </form>
              ) }
            />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ChangePassword.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance( ChangePassword );
