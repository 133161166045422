import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import PageLoader from '../../components/layout/ui/page-loader';
import * as commonFunctions from '../../utilities/commonFunctions';
import UserUtils from '../../utilities/UserUtils';


const DESTINATIONS_DATA = loader('../../graphql/schema/destinationView/destinations.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';

const ShowAllDestinations = (props) => {

    const { history } = props;

    const [destinationsData, setDestinationsData] = useState([]);
    const [size] = useState(8);
    const [page, setPage] = useState(1);
    const [haserror, setHaserror] = useState('');
    const [pageCount, setPageCount] = useState(0);

    const { data, loading, error } = useQuery(DESTINATIONS_DATA, {
        variables: { "first": size, "page": page }
    });

    const handlePage = (pageNo) => {
        let selected = pageNo.selected + 1;
        setPage(selected);
        scrollToTop();
    }


    useEffect(() => {
        if (data && data.destination.data.length > 0) {
            setDestinationsData(data.destination.data);
            setPageCount(data.destination.paginatorInfo.lastPage)
        }
        if (error) {
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
            setHaserror(errorMsg)
        }
    }, [data, loading, error])

    const scrollToTop = () => {
        if ($("#destinationsTab").offset() !== undefined) {
            $('html, body').animate({
                scrollTop: $("#destinationsTab").offset().top
            }, 1000)
        }
    }
    
    const ScheduleTrip = (data) => {
        UserUtils.setDestinationData(JSON.stringify(data));
        $('body').removeClass('modal-open');
        $('.modal-backdrop').removeClass();
        history.push('/schedule-trip');
    }

    return (
        <>
            {loading && <PageLoader loading={loading} />}
            <div className="traveller-page" id="destinationsTab">
                <div className="traveller-row">
                    <div className="traveller-head">
                        <div className="container">
                            <div className="traveller-head-row">
                                <div className="traveller-head-left">
                                    <h2>Show All Destination</h2>
                                </div>
                                <div className="traveller-head-right">
                                    <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
                    <div className="container">
                        <div className="destination-body">
                            {destinationsData.length > 0 && destinationsData.map((d, index) => {
                                return (
                                    <div key={index} className="destination-box">
                                        <div className="box-left">
                                            <figure>
                                                <img
                                                    className="select-dest-img"
                                                    alt={d.name}
                                                    src={`${d.image ? d.image : publicUrl + "assets/images/destination-list/1.png"}`}
                                                />
                                            </figure>
                                        </div>
                                        <div className="box-center">
                                            <h4>{d.name}</h4>
                                            <p>{d.location}</p>
                                        </div>
                                        <div className="box-right">
                                            <i className="fa fa-angle-right" data-toggle="modal" data-target={`#modal-${index}`}></i>
                                        </div>
                                    </div>
                                )
                            })}

                            {pageCount > 1
                                ? <div className="search-pagination">
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-double-left"></i>}
                                            nextLabel={<i className="fa fa-angle-double-right"></i>}
                                            breakLabel={<a href="#/">...</a>}
                                            pageCount={pageCount}
                                            onPageChange={handlePage}
                                            breakClassName={"break-me"}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link" />
                                    </ul>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* popup start here */}
            {destinationsData.map((d, index) => {
                return (
                    <div key={index} className="modal fade destination-body-popup" id={`modal-${index}`}>
                        <div>
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content payment-popup-body">
                                    <div className="popup-scroll">
                                        <button type="button" className="close" data-dismiss="modal"><i className="la la-times"></i></button>
                                        <figure className="destination-img">
                                            <img
                                                className="select-dest-img-modal"
                                                alt={d.name}
                                                src={`${d.image ? d.image : publicUrl + "assets/images/destination-list/1.png"}`}
                                            />
                                        </figure>
                                        <div className="payment-popup-cnt">
                                            <h4>{d.name}</h4>
                                            <em>{d.location}</em>
                                            <p>{d.description}</p>
                                        </div>
                                    </div>
                                    <div className="payment-popup-btn">
                                        <button className="btn btn-yellow" onClick={() => ScheduleTrip(d)}>Huvr there Now!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

        </>
    )
}

ShowAllDestinations.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);


export default enhance(ShowAllDestinations)
