import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import firebase from '../../utilities/firebase';
import 'firebase/database';
import UserUtils from '../../utilities/UserUtils';

const publicUrl = process.env.PUBLIC_URL + '/';

const GET_TRIP_RATE = loader('../../graphql/schema/destinationView/get-trip-rate.graphql');
const SEND_BOOKIN_REQUEST_TO_WALKER = loader('../../graphql/schema/walker/send-booking-request-to-walker.graphql');

const ShowWalkerOnMap = (props) => {

    const { history } = props;

    const [latLong, setLatLong] = useState('');
    const [walkerData, setWalkerData] = useState('');
    const [selectedMark, setSelectedmark] = useState(false);
    const [destinationId, setDestinationId] = useState(0);
    const [tripRate, setTripRate] = useState([]);
    const [tripRateActive, setTripRateActive] = useState(0);
    const [customTripvalue, setCustomTripValue] = useState(1);
    const [customTrip, setCustomTrip] = useState(false);
    const [tripRatePopUp, setTripRatePopUp] = useState(false);
    const [open, setOpen] = useState(true);

    const handleCustomTrip = (event, newValue) => {
        setCustomTripValue(newValue);
    };

    const { data } = useQuery(GET_TRIP_RATE, {
        variables: { "data": destinationId },
        fetchPolicy: 'network-only',
    });

    const [sendBookingRequestToWalker] = useMutation(SEND_BOOKIN_REQUEST_TO_WALKER, {
        onCompleted() {
            setTripRatePopUp(false)
        }
    });

    useEffect(() => {
        const Data = JSON.parse(UserUtils.getDestinationData());
        setLatLong(Data);
        setDestinationId(Data.id);
        if (data) {
            setTripRate(data.getTripRate);
        }
        const list = firebase.database().ref('users');
        list.on('value', (snapshot) => {
            const firebaseData = snapshot.val();
            setWalkerData(firebaseData);
        })
    }, [data]);

    const MapWithAMarker = withScriptjs(withGoogleMap(() =>
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: latLong.latitude, lng: latLong.longitude }}
        >
            <Marker
                options={{ icon: { url: walkerData && walkerData.walkers[62].user.avatar, scaledSize: new window.google.maps.Size(50, 50) } }}
                position={{ lat: parseFloat(walkerData && walkerData.walkers[62].latitude), lng: parseFloat(walkerData && walkerData.walkers[62].longitude) }}
                onClick={() => { setSelectedmark(true) }}
            />
        </GoogleMap>
    ));

    const handleClose = () => {
        setOpen(false);
    };

    const handleScheduleTrip = () => {
        history.push('/schedule-trip');
    }

    const handleStartTrips = () => {
        setTripRatePopUp(true)
        setSelectedmark(false)
    }

    const selectTripRate = (trip_rate, index) => {
        console.log(trip_rate, index)
        setCustomTripValue(1);
        setCustomTrip(false);
        setTripRateActive(index + 1);
    }

    const handleChangeCustomTrip = () => {
        setTripRateActive(0);
        setCustomTripValue(1);
        setCustomTrip(!customTrip);
    }

    const handleclosePopUp = () => {
        setTripRatePopUp(false)
        setSelectedmark(false)
    }

    const handleSubmit = () => {
        sendBookingRequestToWalker({
            variables: {
                data: {
                    walker_id: walkerData && walkerData.walkers[62].user_id,
                    destination_id: destinationId,
                    tripe_price: customTripvalue * 5,
                    tripe_minute: customTripvalue,
                    booking_price_type: "C"
                }
            }
        })
    }

    return (
        <>
            <div className="traveller-page">
                <div className="traveller-row">
                    <div className="traveller-head">
                        <div className="container">
                            <div className="traveller-head-row">
                                <div className="traveller-head-left">
                                    <h2>Show Walker On Map</h2>
                                </div>
                                <div className="traveller-head-right">
                                    <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="travelle-body">
                            <MapWithAMarker
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAd_8tk5z2ySbn4uRcZRXCSIjtac6kYGHI&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `400px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                            <div className="schedule-btn">
                                <button className="btn btn-yellow" onClick={handleScheduleTrip}>Schedule Trip</button>
                            </div>
                        </div>
                    </div>

                    <Dialog aria-labelledby="customized-dialog-title" open={open}>
                        <DialogContent>
                            <div className="schedule-popup-div">
                                <h4>Select A Walker</h4>
                                <p>To Begin Your Trip Select a Walker from Live Map</p>
                                <p>If no walkers are available you can schedule a trip.</p>
                                <div className="select-popup-btn">
                                    <button className="btn btn-yellow" onClick={handleClose}>OK</button>
                                </div>
                            </div>
                        </DialogContent>

                    </Dialog>
                    <Dialog aria-labelledby="customized-dialog-title" open={selectedMark}>
                        <div className="schedule-popup-div walker-map-popup">
                            <DialogContent>
                                <div className="walker-close">
                                    <i className="la la-times" data-toggle="modal" data-target="#myModal" onClick={handleclosePopUp}></i>
                                </div>
                                <div className="walker-row-one">
                                    <figure>
                                        <img src={walkerData && walkerData.walkers[62].user.avatar ? walkerData.walkers[62].user.avatar : publicUrl + "assets/images/inner-page/profile.jpg"} />
                                    </figure>
                                    <h5>{walkerData && walkerData.walkers[62].user.first_name ? walkerData && walkerData.walkers[62].user.first_name : 'N/A'}</h5>
                                </div>
                                {/* <div className="box-right">
                                    <i className="fa fa-times" data-toggle="modal" data-target="#myModal" onClick={handleclosePopUp}></i>
                                </div> */}
                                {/* <div className="d-flex ">
                                    <figure style={{ width: "100px", height: "200px" }}>
                                        <img src={walkerData && walkerData.walkers[62].user.avatar ? walkerData.walkers[62].user.avatar : publicUrl + "assets/images/inner-page/profile.jpg"} />
                                    </figure>

                                    <div>
                                        <h5>{walkerData && walkerData.walkers[62].user.first_name ? walkerData && walkerData.walkers[62].user.first_name : 'N/A'}</h5>
                                    </div>
                                </div> */}
                                <div className="rating_icons">
                                    <Rating
                                        emptySymbol="fa fa-star-o fa-2x"
                                        fullSymbol="fa fa-star fa-2x "
                                        initialRating={parseFloat(walkerData && walkerData.walkers[62].user.over_all_rating)}
                                        readonly
                                    />
                                    <span className="walker-rating-text">{parseFloat(walkerData && walkerData.walkers[62].user.over_all_rating)}</span>
                                </div>
                                <div className="walker-rating-text"><p>No.Of.Walks: <span>{walkerData && walkerData.walkers[62].user.walker_profile.no_of_walks}</span></p></div>
                            </DialogContent>
                            <DialogActions>
                                <div className="text-center">
                                    <button className="btn btn-yellow" onClick={handleStartTrips}>Start Trip</button>
                                </div>
                            </DialogActions>
                        </div>
                    </Dialog>
                    <Dialog aria-labelledby="customized-dialog-title" open={tripRatePopUp}>
                        <div className="schedule-pick-row walker-map-popup">
                            <DialogContent>
                                <div className="walker-close">
                                    <i className="la la-times" data-toggle="modal" data-target="#myModal" onClick={handleclosePopUp}></i>
                                </div>
                                {/* <div className="box-right">
                                    <i className="fa fa-times" data-toggle="modal" data-target="#myModal" onClick={handleclosePopUp}></i>
                                </div> */}
                                <h4 className="package-title">Trip Rates</h4>
                                {tripRate.map((d, index) => {
                                    return (
                                        <>
                                            <div key={index} className={`price-btn ${tripRateActive === (index + 1) ? 'selected-rate' : ''}`} onClick={() => selectTripRate(d, index)}>{`${d.minutes} Minuts for $${d.price}`}</div>
                                        </>
                                    )
                                })}
                                <div className={`price-btn price-btn-last ${customTrip ? 'custom-selected-rate' : ''}`} onClick={handleChangeCustomTrip}>Custom Length</div>
                                {customTrip &&
                                    <Grid item xs>
                                        <Slider value={customTripvalue} onChange={handleCustomTrip} aria-labelledby="continuous-slider" />
                                        <p>{`${customTripvalue} Minutes for $${parseFloat(customTripvalue) * 5}`}</p>
                                    </Grid>
                                }
                            </DialogContent>
                            <DialogActions>
                                <div className="text-center">
                                    <button className="btn btn-yellow" onClick={handleSubmit}>Submit</button>
                                </div>
                            </DialogActions>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

ShowWalkerOnMap.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);

export default enhance(ShowWalkerOnMap);