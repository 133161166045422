import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import PageLoader from '../../../components/layout/ui/page-loader';
import * as commonFunctions from '../../../utilities/commonFunctions';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const COMPLETED_TRIPS_FOR_WALKER= loader('./../../../graphql/schema/auth/completed-trips-for-walker.graphql');

const CompletedTripsForWalker = () => {

  const [completedTripsForWalker,setCompletedTripsForWalker] = useState('');
  const [haserror, setHaserror] = useState('');

  const {data, loading,error} = useQuery(COMPLETED_TRIPS_FOR_WALKER);

  useEffect(() => {
    if (data && data.completedInstantTrip.length > 0) {
      setCompletedTripsForWalker(data.completedInstantTrip);
    }
    if (error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setHaserror(errorMsg)
    }
 }, [data, loading,error])
  console.log('completedTripsForWalker',...completedTripsForWalker)

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-page">
      <div className="traveller-row">
          <div className="traveller-head">
              <div className="container">
                  <div className="traveller-head-row">
                      <div className="traveller-head-left">
                          <h2>Completed Trips for Walker</h2>
                      </div>
                      <div className="traveller-head-right">
                          <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                      </div>
                  </div>
              </div>
          </div>
          {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
          <div className="container">
              <div className="destination-body">
                  {completedTripsForWalker.length <= 0 && <p>No Data To Display</p>}
                  {completedTripsForWalker.length > 0 && completedTripsForWalker.map((value, index) => {
                      return (
                          <div className="destination-box" key={index}>
                              <div className="box-center box-center-full" >
                                  <p className="walk-request-place">{value.destination.name || 'N/A'} <span>({value.tripe_minute}min)</span></p>
                                   <p className="walk-request-text"> <AccessTimeIcon/>{value.created_at || 'N/A'}</p>
                                  <p className="walk-request-name">{value.traveller_details.first_name || 'N/A'},{value.traveller_details.type}</p>                         
                              </div>                              
                          </div>
                           )
                      })}
              </div>
          </div>
        </div>
    </div>
    </>
  )
}

export default CompletedTripsForWalker;
