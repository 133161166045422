import React from 'react';

const WalkerVideoCallRequest = () => {
      return (
            <div>

            </div>
      )
}

export default WalkerVideoCallRequest;