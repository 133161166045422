import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import ReactFlagsSelect from 'react-flags-select';
import { loader } from 'graphql.macro';
import InputMask from 'react-input-mask';
import PageLoader from '../../components/layout/ui/page-loader';

const UPDATE_CARD = loader('../../graphql/schema/payments/update-card.graphql');
const MAKE_CARD_DEFAULT = loader('../../graphql/schema/payments/make-card-default.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';

const UpdateCheckoutForm = (props) => {

  const { history, cardData } = props;

  const apolloClient = useApolloClient();
  const [cardId, setCardId] = useState('');
  const [expDate, setExpDate] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [loader, setLoader] = useState(true);
  const [hasError, setHasError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [expDatePlaceHolder, setExpDatePlaceHolder] = useState('');

  const [expMonth, setExpMonth] = useState('');
  const [expYear, setExpYear] = useState('');

  useEffect(() => {

    setTimeout(() => {
      setLoader(false);
    }, 1000)

    if (cardData) {
      setCardId(cardData.card_id);
      const e_month = (cardData.exp_month < 10 ? '0' + cardData.exp_month : cardData.exp_month);
      const e_year = (parseInt(cardData.exp_year) % 100);
      const ext_date = `${e_month}/${e_year}`;
      setExpDatePlaceHolder(ext_date);
      setExpMonth(e_month);
      setExpYear(e_year);
      setZipCode(cardData.address_zip);
      setCountryCode(cardData.address_country);
    }

  }, [cardData])


  const handleExpDate = (e) => {
    setHasError({});
    setExpDate(e.target.value)
  }

  const handleZipCode = (e) => {
    setZipCode(e.target.value);
  }

  const postResponseData = (res) => {
    apolloClient.writeData({ data: { isLoggedIn: true } });
    let error = {};
    if (res.status === 'CARD_UPDATED') {
      setSuccessMessage(res.message);
      setTimeout(() => {
        history.push('/payment-methods');
      }, 2000);
    } else if (res.status === 'SUCCESS') {
      setSuccessMessage(res.message);
      setTimeout(() => {
        history.push('/payment-methods');
      }, 2000);
    } else {
      error.cardError = res.message;
      setHasError(error);
    }
  };

  const [updateCardSubmit, { loading: loading }] = useMutation(UPDATE_CARD, {
    onCompleted({
      updateCard: {
        message: message,
        status: status,
      },
    }) {
      postResponseData({ status, message });
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ debugMessage }) => (
        <span key={`signup-error-${Math.random()}`}>{debugMessage}</span>
      ));
      let error = {};
      error.cardError = formatedErrors;
      setHasError(error);
    },
  });

  const updateSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    const datesplit = expDate.split('/');
    let expiry_month = await datesplit[0];
    let expiry_year = await datesplit[1];
    expiry_month = (expiry_month) ? expiry_month : expMonth;
    expiry_year = (expiry_year) ? expiry_year : expYear;

    if (expDate === "" && expiry_year === "" && expiry_month === "") {
      errors.cardError = 'Enter Expiry Month & Year.';
      setHasError(errors);
    } else if (parseInt(expiry_month, 10) > 12) {
      errors.cardError = 'Enter valid Expiry Month.';
      setHasError(errors);
    } else if (parseInt(expiry_year, 10) > 3019 && parseInt(expiry_year, 10) < 2019) {
      errors.cardError = 'Enter valid Expiry Year';
      setHasError(errors);
    } else if (countryCode === '') {
      errors.cardError = 'Country is Required.';
      setHasError(errors);
    } else if (zipCode === '') {
      errors.cardError = 'Zip Code is Requied.';
      setHasError(errors);
    } else if (zipCode.length > 6) {
      errors.cardError = 'Enter valid zipcode';
      setHasError(errors);
    } else {
      updateCardSubmit({
        variables: {
          data: {
            "card_id": cardId,
            "exp_month": expiry_month,
            "exp_year": expiry_year,
            "address_zip": zipCode,
            "address_country": countryCode
          },
        },
      });
    }
  }


  const [makeCardDefaultSubmit, { loading: loading2 }] = useMutation(MAKE_CARD_DEFAULT, {
    onCompleted({
      makeCardDefault: {
        message: message,
        status: status,
      },
    }) {
      postResponseData({ status, message });
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      let error = {};
      error.cardError = formatedErrors;
      setHasError(error);
    },
  });

  const makeCardDefault = () => {
    if (cardId) {
      makeCardDefaultSubmit({
        variables: {
          data: cardId
        }
      });
    }
  }

  return (
    <>
      <div>
        {(loading || loader || loading2) && <PageLoader loading={true} />}

        {successMessage !== "" && <p className="success-msg">{`${successMessage}.`}</p>}

        {hasError.cardError && <div className="update-form is-invalid">{hasError.cardError}</div>}
        <div className="travelle-body">
          <div className="edit-form-row">
            <form onSubmit={updateSubmit}>
              <div className="card-number">
                <span>{`XXXX XXXX XXXX ${cardData && cardData.last4}`}</span>
              </div>
              <div className="formin-row">
                <div className="formin-half">
                  <div className="form-in">
                    <label>Exp.date</label>
                    <InputMask
                      placeholder={expDatePlaceHolder}
                      mask="99/99"
                      value={expDate}
                      onChange={handleExpDate}>
                    </InputMask>
                    <figure className="form-card">
                      <img src={publicUrl + "assets/images/inner-page/master.png"} alt="" />
                    </figure>
                  </div>
                </div>
                <div className="formin-half">
                  <div className="form-in">
                    <label>CVC</label>
                    <input
                      placeholder="***"
                      maxLength={4}
                      type="text"
                      name="cvcCode"
                      disabled
                    />
                    <figure className="form-card">
                      <img src={publicUrl + "assets/images/inner-page/master.png"} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="form-in">
                <label>States</label>
                <ReactFlagsSelect
                  searchPlaceholder="Search countries"
                  searchable
                  selected={countryCode}
                  onSelect={code => setCountryCode(code)}
                />
              </div>
              <div className="form-in">
                <label>Zip Code</label>
                <input
                  maxLength={6}
                  name="zipCode"
                  placeholder="00000"
                  type="text"
                  value={zipCode}
                  onChange={handleZipCode}
                />
              </div>
              <div className="form-flex">
                <div className="form-btn">
                  <button className="btn btn-yellow">Update Card Information</button>
                </div>
                <div className="form-btn">
                  <button className="btn btn-yellow" onClick={makeCardDefault}>Set Card as Default</button>
                </div>
              </div>
            </form>
            {/* <div className="form-btn form-btn-right default-btn-0">
             
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

UpdateCheckoutForm.propTypes = {
  history: PropTypes.object.isRequired,
  cardData: PropTypes.object.isRequired,
}

const enhance = compose(
  withRouter,
);

export default enhance(UpdateCheckoutForm);