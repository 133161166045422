import React,{useState} from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { Form,Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { useMutation,useApolloClient } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';


const CREATE_STRIPEACCOUNT_AND_GENERATE_LINK = loader('../../graphql/schema/payments/create-stripe-account-and-generate-link.graphql');

const PayoutAccount=()=>{

const apolloClient = useApolloClient();  
const [countryCode, setCountryCode] = useState('');
const [hasError, setHasError] = useState('');
const [successMessage,setSuccessMessage] = useState('');


const postCreateStripeAccountAndGenerateLink = (resUser) => {
  console.log(resUser,'------- post user')
  apolloClient.writeData({ data: { ispostCreateStripeAccountAndGenerateLink: true }});
   if (resUser.status === "SUCCESS") {
   setSuccessMessage(resUser.message);
   window.location=`${resUser.url}?return_url=${resUser.return_url}&refresh_url=${resUser.refresh_url}`
  }
   }

  const [submitCreateStripeAccountAndGenerateLink, { loading: CreateStripeAccountAndGenerateLinkLoading }] = useMutation(CREATE_STRIPEACCOUNT_AND_GENERATE_LINK, {
     onCompleted({
      createStripeAccountAndGenerateLink: {
        url,
        return_url,
        refresh_url,
        message,
        status
     },
     }) {
       if(status === "SUCCESS") {
         postCreateStripeAccountAndGenerateLink({url,return_url,refresh_url, status, message });
       }
      },
     onError(errors) {
       const formatedErrors = errors.graphQLErrors.map(({ message }) => (
         <span key={`signup-error-${Math.random()}`}>{message}</span>
       ));
       setHasError(formatedErrors);
     },
   });
 
 const validate = () =>{
 
  const errors = {};

  if (!countryCode) {
    errors.country = 'Country is required.';
  }
  return errors;
};

const onSubmitCreateStripeAccountAndGenerateLink=()=>{
     submitCreateStripeAccountAndGenerateLink({
       variables: {
         country: countryCode
    },
  })  
}
return(
    <div className="traveller-page">
    {CreateStripeAccountAndGenerateLinkLoading && <PageLoader loading={CreateStripeAccountAndGenerateLinkLoading} />}
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Payout Accounts</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>
            <div className="container">
              <div className="travelle-body">
                <div className="destination-para">
                  <p>This is the account where Huvr will deposit your earnings.</p>
                  <p>A week for Huvr runs from Monday at 4:00 AM to the following  Monday at 3:59 AM. All walks during that time period will count towards that week&apos;s pay period.</p>
                </div>
                <div className="edit-form-row">
                {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
                 {hasError && <p className="is-invalid">{hasError}</p>}
                <Form
                onSubmit={ onSubmitCreateStripeAccountAndGenerateLink}
                validate={ validate }
                render={ ( {handleSubmit,submitting} ) => (
                  <form
                    onSubmit={ handleSubmit}
                    noValidate
                  >
                  <React.Fragment>
                    <div className="form-in">
                    <Field name="country">
                      {({ input }) => (
                        <div>
                          <ReactFlagsSelect
                            {...input}
                            searchPlaceholder=""
                            searchable
                            selected={countryCode}
                            onSelect={code => setCountryCode(code)}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                    </React.Fragment>
                    <span><h3>Linked Payment Methods</h3></span>
                    <span><h4>Attention Needed</h4></span>
                  <div className="form-btn">
                   
                    <Button type="submit" onSubmit={submitting}>Connect to Stripe Account</Button>
                 </div>
                 </form>
                 )}
              />
             </div>
            </div>
           </div>
          </div>
         </div>
    )
}
export default PayoutAccount;