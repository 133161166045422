import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const publicUrl = process.env.PUBLIC_URL + '/';

const WalkerHelp=()=>{

   const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };


    return(
        <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Help & support</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="support-row">
             
              <div>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Account & Payment</Typography>
              </AccordionSummary>
              <div>
          <Accordion>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>Can I change my Payout Account?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
               Yes, from Walker mode tap the hamburger icon and then select Payout account.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
          expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>How can I add my picture to my account?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
                Tap edit my account. Then tap picture frame next to your name at the top. Now tap Take Photo or choose from library.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
     <Accordion>
     <AccordionSummary
          expandIcon={<ExpandMoreIcon />} 
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
         <Typography>How do I edit my account?</Typography>
          </AccordionSummary>
          <AccordionDetails>
           <Typography>
                Tap hamburger menu. Then Tap Edit My Account. Then Edit Name, Phone Number, Email,and Password.
           </Typography>
         </AccordionDetails>
       </Accordion>
      </div>
      <div>
    <Accordion>
    <AccordionSummary
     expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>How Will funds be transferred?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
                Funds will be transferred through Stripe Connect.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
     <Accordion>
     <AccordionSummary
         expandIcon={<ExpandMoreIcon />} 
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
         <Typography>Legal documentation</Typography>
          </AccordionSummary>
          <AccordionDetails>
           <Typography>
                  Terms of use 3/10/21 Privacy policy
           </Typography>
         </AccordionDetails>
       </Accordion>
      </div>
      <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>When do I get paid as a Huvr Walker?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
                 Huvr Walkers who join our team are contractors and paid on a weekly basis. Our Huvr pay cycle runs from Monday at 4:00AM to the following Monday at 3:59AM (Moutain Time) USA. All Walks during the above timeframe will count towards a week&apos;s pay. Processing times may vary with each country and subject to your banks processing cycle.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>How are earnings calculated for Walkers?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
                 Walkers get 70% of gross after fees are taken out. For example a 10 minute walk is $15.00. Subtract 3.5% plus $0.35 then multiply by .70 to get what a walker will make.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     </Accordion>
          </div>
        <div>
     <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
         <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
              >
              <Typography>Equipment Requirements</Typography>
              </AccordionSummary>
              <div>
    <Accordion>
    <AccordionSummary
          expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>What is the Minimun approved equipment?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
               iphone requirements are IOS 10 and up. Android requirements TBDWebapp requirement TBD
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
          expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>What is a Walker kit and how do I get one?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
                You can order a Walker Kit when you sign up to be a Walker by selecting the &quot;Make Money Walking &quot; from the main menu.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     </Accordion>
    </div>
    <div>
     <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Open support ticket or give feedback</Typography>
              </AccordionSummary>
              <div>
    <Accordion>
    <AccordionSummary
         expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography >How do I submit a support ticket or provide feedback?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
               Please go to <a href=""> Walker Support</a> to open a support ticket or provide feedback on the Huvr app. 
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     </Accordion>
     </div>
     <div>
     <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
         <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Legal (As a Walker)</Typography>
              </AccordionSummary>
              <div>
              <figure className="coming-soon">
                <img src={publicUrl + "assets/images/inner-page/comingSoon.png"} alt="" />
              </figure>
              </div>
          </Accordion>
          </div>
        <div>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
         <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Understanding Huvr (As a Walker)</Typography>
        </AccordionSummary>
        <div>
        <Accordion>
         <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography >Is Huvr available Worldwide?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
            Huvr will roll out specific cities globally and continue to expand for gig workers. By expressing interest we can tell you when your city goes live so you can secure your place. In terms for those using the app, it will be made available on IOS & Android and subject to each country and their release regulations.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     </Accordion>
     </div>
     <div>
     <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Using the App (As a Walker)</Typography>
              </AccordionSummary>
              <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>Can I cancel a walk request?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
               Yes, a Walker can cancel a request from a Traveler however, If a Walker cancels a trip less than 24 hours out, Huvr will charge the Walker $5.00 and the Traveler is sent a notification about the cancelled trip and how to reschedule and  gets a $5.00 credit. Additionally any cancellation by a Traveler or Walker that is less than 3 hours out, that party will be charged a $10 cancellation fee penalty.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>Can I use the app as a Traveler?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
              Yes, you are required to sign up as a traveler before you can sign up as a walker.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>How Can I Make money walking?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
                Update after Make money walking has been updated
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>How can I see my earnings and number of walks completed for the day?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
                From Walker mode tap outside the menu screen.Then Tap the blue $ at the top of your screen
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>How do I accept a walk request?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
             To be added
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>How do I create or update My Walker Profile?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
               From Walker Mode tap My walker profile. In Name field add your name. In City or Town field add your City or Town you live in. In STATE or PROVINCE field add the State or province you live in. In Country field use the drop down list to select the Country you live in. In the TOP 3 NEAREST HUVR DESTINATIONS fields add the top 3 nearest HUVR Destinations close to where you live. The Number of Walks field will automatically update the number of walks you have taken. In the LANGUAGE I SPEAK field add the Languages you Speak fluently. In the EQUIPMENT I HAVE options tap to select the equipment you have. In the Walker Description field add a Description about your self including where you live. Tap update once you are done editing your Walker Profile  
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>How do I Go Offline so that I am not available as a Walker?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
               From Walker mode  tap outside the menu. Then tap the green Go OFFLINE button at the bottom of the screen. You are now offline!
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
     <Accordion>
     <AccordionSummary
     expandIcon={<ExpandMoreIcon />} 
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
         <Typography>How do I Go Online to make myself  available as a Walker?</Typography>
          </AccordionSummary>
          <AccordionDetails>
           <Typography>
                 From Walker Mode tap outside the menu so that you can see the map. Then Tap the Blue Go ONLINE button at the bottom of your screen. You are now available for walk requests!
           </Typography>
         </AccordionDetails>
       </Accordion>
      </div>
      <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />} 
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>How does Huvr calculate stats?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
               TIME this number indicates the total time you were online for the week showing, and not just the amount of time you spent walking on trips. WALKS this number indicates the total number of walks you went on for the week showing. Avg . Walk. TIME this number indicates the average length of walk you&apos;ve taken for all the walks you&apos;ve taken durning the week showing.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
    
     <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>Where can I see My Ratings?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
                From Walker mode tap the hamburger menu, then tap ratings.
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
    
     <div>
     <Accordion>
     <AccordionSummary
     expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
         <Typography>What do I do if the Huvr app crashed the first time I use it?</Typography>
          </AccordionSummary>
          <AccordionDetails>
           <Typography>
                If the Huvr app crashes the first time you use it please restart the Huvr App.
           </Typography>
         </AccordionDetails>
       </Accordion>
      </div>
      </Accordion>
      </div>
      <div>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Walker Sign up</Typography>
              </AccordionSummary>
              <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography >Are there set hours I need to work?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
             You can work the hours that suit you. This is a flexible arrangement, however there is supply and demand. So this may also impact your earnings subject to the times or hours of the day you work
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>What are the Requirements that need to be met for a gig opportunity?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
              Background Check Order Walker Kit Link Bank Account Submit 1099 or W-8BEN Sign Walker Terms Complete Walker Training Upload Profile  Picture Walker Training Create Stripe Connect account 
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     <div>
    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>What do I have to do to get setup and approved as a Walker?</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <Typography>
              To become an approved Walker you will need to complete the following information. Background Check, order Walker Kit, Link Bank Account, Submit 1099 or W-8BEN,Sign Walker Terms, Complete Walker Training and Upload Profile Picture 
          </Typography>
        </AccordionDetails>
      </Accordion>
     </div>
     </Accordion>
     </div>
   
 </div>
</div>
</div>
</div>
      
    )
}
  export default WalkerHelp;
