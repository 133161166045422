import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Rating from '@material-ui/lab/Rating';
import { TextField } from 'final-form-material-ui';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import PageLoader from '../../components/layout/ui/page-loader';

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const SAVE_RATING = loader('../../graphql/schema/walker/save-rating.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';

const WalkerRating = () => {

  const [hasError, setHasError] = useState('');

  const { data: myDetails, loading } = useQuery(MY_DETAILS);

  const [updateWalkerKit] = useMutation(SAVE_RATING, {
    // onCompleted() {
    //   setOpenPopup( true );
    // },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  const onSubmitRating = (values) => {
    console.log('values-----', values)
    updateWalkerKit({
      variables: {
        comment: values.comment,
        rating: values.rating,
        tip_amount: values.tip_amount,
        walker_id: values.walker_id,
        booking_id: values.booking_id,
      },
    });

  };

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Walker Rating</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="travelle-body">
              <div className="profile-row">
                <div className="profile-left">
                  <figure>
                    <img src={myDetails && myDetails.me.profile_image ? publicUrl + myDetails.me.avatar : 'assets/images/testimonial-img1.jpg'} alt="profile" />
                  </figure>
                  <div className="walker-training-div">
                    <div className="profile-title">
                      <h5>
                        {myDetails && myDetails.me.first_name}
                        {' '}
                        {myDetails && myDetails.me.last_name}
                      </h5>
                    </div>
                    <div className="profile-title">
                      <p> Rate
                      {myDetails && myDetails.me.first_name}
                        {' '}
                        {myDetails && myDetails.me.last_name}&apos;s walker trip experience provided for you.
                    </p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="edit-form-row">
                {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
                <Form
                  onSubmit={onSubmitRating}
                  render={({ handleSubmit, submitting }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <div className="form-in star-rating">
                        <Field
                          id="rating"
                          name="rating"
                          margin="normal"
                          variant="outlined"
                          component={Rating}
                        />
                      </div>
                      <div className="form-in">
                        <Field
                          id="comment"
                          placeholder="Write comment"
                          type="text"
                          name="comment"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>

                      <div className="form-in">
                        <span>Add a tip value</span>
                        <Field
                          id="tip_amount"
                          placeholder="Enter tip value"
                          type="text"
                          name="tip_amount"
                          margin="normal"
                          disabled
                          variant="outlined"
                          component={TextField}
                        />
                      </div>

                      <div>Report an issue</div>

                      <div className="form-btn">
                        <Button type="submit" disabled={submitting}>Submit</Button>
                      </div>
                    </form>
                  )}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

WalkerRating.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(WalkerRating);
