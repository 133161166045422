import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import PropTypes from "prop-types";
import OtpInput from 'react-otp-input';
import { loader } from 'graphql.macro';
import UserUtils from '../../../utilities/UserUtils';
import PageLoader from '../../layout/ui/page-loader';

let publicUrl = process.env.PUBLIC_URL + '/';
const VERIFY_OTP = loader('./../../../graphql/schema/auth/verify-otp.graphql');

const VerifyOtp = ( props ) => {

  const { history } = props;
  const apolloClient = useApolloClient();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState({});
  const [checkOtp, setCheckOtp] = useState( false );

  useEffect(() => {
    const phone = UserUtils.getPhoneNumber();
    if (phone !== null) {
      setPhoneNumber(phone);
    } else {
      history.push('/register');
    }
  }, [])

  const handleChangeOtp = (otp) => {
    setPhoneOtp(otp);
    setCheckOtp(false);
  }

  const validatePhoneOtp = () => {
    let formIsValid = true;
    let errors = {};
    if (phoneOtp === false || phoneOtp === '') {
      formIsValid = false;
      errors["phoneOtp"] = "Please enter OTP.";
    } else if (phoneOtp.length < 5) {
      formIsValid = false;
      errors["phoneOtp"] = "Please enter valid OTP.";
    }
    setHasError(errors);
    return formIsValid;
  }

  const postSignUp = (resUser) => {
    apolloClient.writeData({ data: { isLoggedIn: false } });
    let error = {};
    if (resUser.status === "OTP_NOT_VERIFIED") {
      error['otpError'] = resUser.message;
      setHasError(error);
      setCheckOtp(true);
    } else {
      setSuccessMessage(resUser.message);
      setTimeout(() => {
        history.push('/register-profile-details');
      }, 1500)
    }
  };

  const { loading } = useQuery(VERIFY_OTP, {
    variables: {
      data: {
        "otp": phoneOtp,
        "phone": phoneNumber
      },
    },
    fetchPolicy: 'network-only',
    skip: checkOtp === false,
    onCompleted({
      enterOtp: {
        status: status,
        message: message
      },
    }) {
      postSignUp({ status, message });
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      let error = {};
      error['otpError'] = formatedErrors;
      setHasError(error);
    }
  });

  const onSubmitOTP = (e) => {
    e.preventDefault();
    if (validatePhoneOtp()) {
      setCheckOtp(true);
    }
  }

  return (
    <div className="signup-container-row">
      {loading && <PageLoader loading={loading} />}
      <div className="signup-row">
        <p className="modal-close" ><Link to="/"><i className="la la-times" /></Link></p>
        <div className="signup-left">
          <div className="signup-cnt">
            <h4 component="h4">Sign Up</h4>
            <p>Huvr is an app connecting virtual Travelers to human Huvr approved Walkers across the globe. They provide individual or group experiences unlike anything on the market today.</p>
            <Link to="/" className="btn"><i className="fa fa-external-link"></i> Learn More</Link>
          </div>
        </div>
        <div className="signup-right">
          <div className="signup-form">
            <div className="form-title">
              <figure>
                <img src={publicUrl + "assets/images/favicon.png"} alt="" />
              </figure>
              <h4 component="h4">Welcome to Huvr</h4>
            </div>
            <form onSubmit={onSubmitOTP}>
              {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
              {hasError.otpError !== undefined && <p className="is-invalid">{hasError.otpError}</p>}
              <div className="form-input-box-1">
                <label variant="subtitle2" component="label">Enter OTP Here</label>
                <div className="otp-input">
                  <OtpInput
                    variant="outlined"
                    name="phoneOtp"
                    margin="normal"
                    shouldAutoFocus={true}
                    className={``}
                    onChange={handleChangeOtp}
                    numInputs={5}
                    value={phoneOtp}
                    separator={<span>-</span>
                    }
                  />
                </div>
                <div className='is-invalid'>{hasError.phoneOtp !== undefined ? hasError.phoneOtp : ''}</div>
              </div>
              <div className="form-btn">
                <input type="submit" color="inherit" value="Verify" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

VerifyOtp.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance( VerifyOtp );