import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

const Layout = (props) => {
  let { component: Component } = props;

  const urlPath = props.location.pathname;
  let showHideNav = true;
  if (urlPath === '/login' ||
    urlPath === '/register' ||
    urlPath === '/register-profile-details' ||
    urlPath === '/verify-otp' ||
    urlPath === '/verify-login' ||
    urlPath === '/forgot-password'
  ) {
    showHideNav = false;
  }

  return (
    <React.Fragment>
      {showHideNav && <Header />}
      <Component />
      {showHideNav && <Footer />}
    </React.Fragment>
  )
}

Layout.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

const enhance = compose( withRouter );

export default enhance( Layout );
