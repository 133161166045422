import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { CountryDropdown } from 'react-country-region-selector';
import PageLoader from '../../components/layout/ui/page-loader';

const SAVE_DESTINATION = loader('../../graphql/schema/destinationView/save-destination.graphql');

const CreateDestinationWishList = (props) => {

  const { history } = props;

  const [country, setCountry] = useState('');
  const [haserror, setHaserror] = useState('');
  const [errorMes, serErrorMes] = useState('');

  const [saveDestination, { loading }] = useMutation(SAVE_DESTINATION, {
    onCompleted() {
      history.push('/destination-wishlist');
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));

      setHaserror(formatedErrors);
    },

  });

  const validate = values => {
    const errors = {};

    if (!values.Destination) {
      errors.Destination = 'Please Enter Destination';
    }

    if (country) {
      serErrorMes('');
    }

    if (!country) {
      serErrorMes('no country is selected');
    }

    if (!values.City) {
      errors.City = 'Please Enter City';
    }

    return errors;

  };

  const selectCountry = (val) => {
    setCountry(val);
  }

  const onSubmit = (values) => {
    saveDestination({
      variables: {
        data: {
          destination: values.Destination,
          city: values.City,
          country: country,
        },
      },
    });
  };

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Create New Destination Wish List</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>
          {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
          <div className="container">
            <div className="travelle-body">
              <p className="destination-para">Submit your favorite destnations want to Huvr to, and we&apos;all start working on getting walkers available in those places.</p>
              <div className="edit-form-row">
                <Form
                  onSubmit={onSubmit}
                  validate={validate}
                  render={({ handleSubmit, submitting }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <div className="form-in">
                        <span>Destination</span>
                        <Field
                          fullWidth
                          name="Destination"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>City</span>
                        <Field
                          fullWidth
                          name="City"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>Country</span>
                        <Field name="Country" component={TextField}>
                          {({ input }) => (
                            <div>
                              <CountryDropdown
                                {...input}
                                value={country}
                                onChange={val => selectCountry(val)}
                              />
                            </div>
                          )}
                        </Field>
                        {errorMes && <p className="is-invalid">{errorMes}</p>}
                      </div>

                      <div className="form-btn">
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={submitting}
                        >
                          Submit
                        </Button>
                      </div>
                      <div className="form-btn">
                      </div>
                    </form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

CreateDestinationWishList.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(CreateDestinationWishList);
