import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import UserUtils from '../../utilities/UserUtils';
import UpdateCheckoutForm from './update-checkout-form';

const UpdateCard = () => {

  const [cardData, setCardData] = useState('');

  useEffect(() => {
    const cardDetails = JSON.parse(UserUtils.getCardId());
    setCardData(cardDetails);
    return () => {
      UserUtils.removeCardId();
    };
  }, []);

  return (
    <>
      <div className="traveller-page">
        <div className="traveller-row">
          <div className="traveller-head">
            <div className="container">
              <div className="traveller-head-row">
                <div className="traveller-head-left">
                  <h2>Update Card</h2>
                </div>
                <div className="traveller-head-right">
                  <p>Real time virtual travel at a click. <span className="app-icon"><i className="fa fa-apple"></i> <i className="fa fa-android"></i></span></p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <UpdateCheckoutForm cardData={cardData} />
          </div>
        </div>
      </div>
    </>
  )
}

const enhance = compose(
  withRouter,
);

export default enhance(UpdateCard);